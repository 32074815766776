import {React, useRef} from 'react';
import { useState } from 'react';
import {useAuthContext} from "../../hooks/useAuthContext";
import {useSitesContext} from "../../hooks/useSitesContext";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {MdOutlineDelete, MdOutlineUpload} from "react-icons/md";
import {IoAddCircleOutline} from "react-icons/io5";
import {Alert} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";
import {v4} from "uuid";

const SitesEditModal = ({site}) => {
    // State variables for the form. We use these in the handleSubmit function.
    const { siteName, latitude, longitude, description, image, audio, _id } = site.deleteSiteId;
    const [siteNameV, setSiteNameV] = useState(siteName);
    const [siteDescription, setSiteDescription] = useState(description);
    const [siteLat, setSiteLat] = useState(latitude);
    const [siteLong, setSiteLong] = useState(longitude);
    const [imgUpload, setImgUpload] = useState(image);
    const [audioUpload, setAudioUpload] = useState(audio);
    const [currAudio, setCurrAudio] = useState(audio);
    const siteId = _id;

    // These states handle new images to be uploaded to Firebase. We create a new state to prevent us having to
    // upload the images as soon as they are selected. Instead, we upload them if the user chooses to submit the form.
    const inputImage = useRef(null);
    const audioToUpload = useRef(null);
    const [newImages, setNewImages] = useState([]);
    const [delImages, setDelImages] = useState([]);

    // Tracks if the user makes changes - warns them if they try to leave the page without saving.
    const [changesMade, setChangesMade] = useState(false);

    // AuthContext is used to pass a valid user token to the backend. SitesContext is used to dispatch the new site to
    // the global state.
    const { user } = useAuthContext();
    const { dispatch } = useSitesContext();

    // Handles form submission. We upload the image to the Firebase storage first, then send a POST request to the
    // backend to create a new Site in the database.
    const handleSubmit = async (e) => {
        try {
            if (delImages.length > 0) {
                const storage = getStorage();
                for (const img of delImages) {
                    const imgRef = ref(storage, img);
                    await deleteObject(imgRef);
                    let newImgs = imgUpload.filter((image) => image !== img);
                    setImgUpload(newImgs);
                }
                setDelImages([]);
            }
            if (newImages.length > 0) {
                const storage = getStorage();
                for (const img of newImages) {
                    const imgRef = ref(storage, `images/${img.name + v4()}`);
                    await uploadBytes(imgRef, img);
                    const url = await getDownloadURL(imgRef);
                    let newImgs = imgUpload;
                    newImgs.push(url);

                    setImgUpload(newImgs);
                }
                setNewImages([]);
            }
        } catch (error) {
            console.error("Error handling image upload:", error);
        }

        let url = "";

        try {
            if (currAudio !== audio) {
                // Construct reference to the audio file using the full path stored in the database
                const storage = getStorage();
                const audioRefDelete = ref(storage, audio);

                // Delete the audio file
                await deleteObject(audioRefDelete);

                // Upload the new audio file
                const audioRef = ref(storage, `audios/${audioUpload.name + v4()}`);
                await uploadBytes(audioRef, audioUpload);
                url = await getDownloadURL(audioRef);
                setAudioUpload(url);
            }
        } catch (error) {
            console.error("Error handling audio upload:", error);
        }

        // The remainder of this function goes in a try/catch block to handle any errors.
        try {
            const updatedSite = {
                siteName: siteName,
                latitude: siteLat,
                longitude: siteLong,
                description: siteDescription,
                image: imgUpload,
                audio: (url ? url : audio),
            };

            // Send POST request to backend. We pass the user token in the Authorization header, and the new site
            // object in the body of the request.
            const res = await fetch(process.env.REACT_APP_API_URL + "/api/sites/" + _id, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
                body: JSON.stringify(updatedSite)
            });

            // If the response is OK, we dispatch the new site to the global state and alert the user that the site
            // was uploaded successfully. Otherwise, we alert the user that there was an error.
            if (res.ok) {
                const responseData = await res.json();
                dispatch({ type: "DELETE_SITE", payload: responseData });
                dispatch({ type: "CREATE_SITE", payload: responseData });
                alert("Site updated successfully!");
                setChangesMade(false);
            } else {
                const errorData = await res.json();
                alert(`Error updating site: ${errorData.message}`);
            }
        } catch (error) {
            console.error("Error during image update or PATCH request:", error);
            alert("Error during site update. Please try again.");
        }
    }

    // Create a custom icon using the imported pin icon
    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });

    // handles click on the map
    const handleMapClick = (e) => {
        setSiteLat(e.latlng.lat.toFixed(6));
        setSiteLong(e.latlng.lng.toFixed(6));
        setChangesMade(true);
    };

    // Places the pointer on the map
    const PointerPlaced = () => {
        useMapEvents({
            click: handleMapClick,
        });

        return siteLat && siteLong ? (
            <Marker position={[parseFloat(siteLat), parseFloat(siteLong)]} icon={customIcon} />
        ) : null;
    };

    // Handles removing images from a site.
    const handleImgDelete = (source) => {
        if ((imgUpload.length + newImages.length) > 1) {
            let delImgs = delImages;
            delImgs.push(source);
            setDelImages(delImgs);
            setImgUpload(imgUpload.filter((img) => img !== source));
            setChangesMade(true);
        } else {
            alert("You must have at least one image for the site!")
        }
    }

    // Handles removing images when they've not been uploaded to Firebase in a previous session.
    function handleLocalImgDelete(img) {
        if ((imgUpload.length + newImages.length) > 1) {
            const newImageUpload = newImages.filter((image) => image !== img);
            setNewImages(newImageUpload);
            setChangesMade(true);
        } else {
            alert("You must have at least one image for the site!")
        }
    }

    // Handles adding new images to site object from local storage. Note we don't upload unless the user hits Submit.
    const handleImgUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewImages((image) => [...image, file]);
            setChangesMade(true);
        }
    }

    const handleAudioUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const urlObj = URL.createObjectURL(file);
            setCurrAudio(urlObj);
            setAudioUpload(file);
            setChangesMade(true);
        }
    }

    // Displays the upload form to the user.
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
            className={"upload-form"}>
            <span>
                {
                    changesMade && (
                        <Alert color={"warning"} icon={HiInformationCircle} style={{boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.25)'}} rounded>
                            Careful! You have unsaved changes.
                        </Alert>
                    )
                }
                <div className="side1">
                    <span>
                        <span>
                            <input
                                type="text"
                                id="siteName"
                                name="siteName"
                                value={siteNameV}
                                onChange={(e) => {
                                    setSiteNameV(e.target.value);
                                    setChangesMade(true);
                                }}
                                required
                            />
                            <textarea
                                id="siteDescription"
                                name="siteDescription"
                                value={siteDescription}
                                onChange={(e) => {
                                    setSiteDescription(e.target.value);
                                    setChangesMade(true);
                                }}
                                required
                            />
                        </span>
                        <div className="site-upload-map">
                            <MapContainer className={"osm"} center={[siteLat, siteLong]} zoom={15}
                                          style={{height: '300px', width: '100%'}}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <PointerPlaced/>
                            </MapContainer>
                        </div>
                    </span>
                    <input type="file" id="image" ref={inputImage} onChange={handleImgUpload}
                           style={{display: 'none'}}/>
                    <input type="file" id="audio" ref={audioToUpload} onChange={handleAudioUpload}
                           style={{display: 'none'}}/>
                    {
                        audioUpload && (
                            <div className={"audio-upload"}>
                                <h2>Edit Audio</h2>
                                <span>
                                    <audio src={currAudio} controls/>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            audioToUpload.current.click();
                                        }}
                                    >
                                        <MdOutlineUpload />
                                        <p>Replace Audio</p>
                                    </button>
                                </span>
                            </div>
                        )
                    }
                    {
                        imgUpload && (
                            <div className="img-gallery">
                                <h2>Edit Image Set (Total: {imgUpload.length + newImages.length})</h2>
                                <div className="gallery">
                                    {
                                        imgUpload.map((img, index) => {
                                            return (
                                                <div className="img-card" key={index}>
                                                    <img src={img}/>
                                                    <div className="img-edit">
                                                        <button
                                                            className="btn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleImgDelete(img);
                                                            }}
                                                        >
                                                            <MdOutlineDelete/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        newImages && newImages.map((img, index) => {
                                            var reader = new FileReader();
                                            reader.onload = function () {
                                                // When the image is loaded, set the src attribute of the img element
                                                document.getElementById(`image-${index}`).src = reader.result;
                                            };
                                            reader.readAsDataURL(img);

                                            return (
                                                <div className="img-card" key={index}>
                                                    {/* Assign an id to the img element */}
                                                    <img id={`image-${index}`} alt={`Image ${index}`}/>
                                                    <div className="img-edit">
                                                        <button
                                                            className="btn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleLocalImgDelete(img);
                                                            }}
                                                        >
                                                            <MdOutlineDelete/>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className="add-card" onClick={(e) => {
                                        e.preventDefault();
                                        inputImage.current.click();
                                    }}>
                                        <IoAddCircleOutline style={{width: '30px', height: '30px'}}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <input className="submitBtn" type="submit" value="Save"/>
            </span>
        </form>
    )
}

export default SitesEditModal;