/**
 * SITESCONTEXT.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2023.
 */

// Import dependencies:
import { createContext, useReducer } from 'react';

/**
 * This context is used to retain local state of the individual sites loaded in. We do this to prevent the application
 * having to constantly make requests to the backend, therefore reducing the load on the backend.
 */

export const SitesContext = createContext();

// Updates the state of the sites context. Accepts two parameters. 'state' is the current state of the context, and
// 'action' is the action to be performed on the state.
export const sitesReducer = (state, action) => {
    switch (action.type) {
        // These are the list of VALID actions that can be performed on the state. We can add others here if needed.
        // The payload is the data that is passed to the reducer to be added to the state.
        case 'SET_SITES':
            return {
                // This is like saying variable x = some value.
                // Set sites to be whatever the contents of the action payload are.
                sites: action.payload
            }
        case 'CREATE_SITE':
            return {
                // This is like appending some value X to the end of an array. We add the action payload to whatever's
                // already stored in the state.sites object.
                sites: [action.payload, ...state.sites]
            }
        case 'DELETE_SITE':
            return {
                // This removes the site from the state.sites object, by filtering out the site with the ID of the
                // action payload.
                sites: state.sites.filter((w) => w._id !== action.payload._id)
            }
        default:
            // This is the default action when an action isn't selected. It just returns the state as it is.
            return state
    }
}

export const SitesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sitesReducer, {
        sites: null
    })

    // We return the following element to the application, a context provider. Any element wrapped between these tags
    // (i.e. the {children}) will have access to this context's state and dispatch functions.
    return (
        <SitesContext.Provider value={{ ...state, dispatch }}>
            { children }
        </SitesContext.Provider>
    )
}