import React from 'react';
import { useState } from 'react';
import { storage } from '../../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import {useAuthContext} from "../../hooks/useAuthContext";
import {useSitesContext} from "../../hooks/useSitesContext";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import {useNavigate} from "react-router-dom";

const SitesUploadModal = () => {
    // State variables for the form. We use these in the handleSubmit function.
    const [siteName, setSiteName] = useState('');
    const [siteDescription, setSiteDescription] = useState('');
    const [siteLat, setSiteLat] = useState('');
    const [siteLong, setSiteLong] = useState('');
    const [imgUpload, setImgUpload] = useState([]);
    const [audioUpload, setAudioUpload] = useState(null);

    // AuthContext is used to pass a valid user token to the backend. SitesContext is used to dispatch the new site to
    // the global state.
    const { user } = useAuthContext();
    const { dispatch } = useSitesContext();

    // Navigate to the sites page
    const navigate = useNavigate();

    // Handles form submission. We upload the image to the Firebase storage first, then send a POST request to the
    // backend to create a new Site in the database.
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if user has supplied an image to upload. If not, do not continue.
        if (!imgUpload) {
            alert("Please select an image to upload!");
        }

        // Get a reference as to where the image will be stored in our Firebase bucket. We use the 'v4()' function to
        // generate a random UUID for the image name, to avoid any potential conflicts.
        const audioReference = ref(storage, `audios/${audioUpload.name + v4()}`);

        // The remainder of this function goes in a try/catch block to handle any errors.
        try {
            // Upload the image to Firebase and store the URL in a variable.
            let firebaseURL = [];

            for (let i = 0; i < imgUpload.length; i++) {
                const imgRef = ref(storage, `images/${imgUpload[i].name + v4()}`);
                await uploadBytes(imgRef, imgUpload[i]);
                const url = await getDownloadURL(imgRef);
                firebaseURL.push(url);
            }

            await uploadBytes(audioReference, audioUpload);
            const firebaseAudioURL = await getDownloadURL(audioReference);

            const newSite = {
                siteName: siteName,
                latitude: siteLat,
                longitude: siteLong,
                description: siteDescription,
                image: firebaseURL,
                audio: firebaseAudioURL
            };

            // Send POST request to backend. We pass the user token in the Authorization header, and the new site
            // object in the body of the request.
            const res = await fetch(process.env.REACT_APP_API_URL + "/api/sites/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
                body: JSON.stringify(newSite)
            });

            // If the response is OK, we dispatch the new site to the global state and alert the user that the site
            // was uploaded successfully. Otherwise, we alert the user that there was an error.
            if (res.ok) {
                const responseData = await res.json();
                dispatch({ type: "CREATE_SITE", payload: responseData });
                alert("Site uploaded successfully!");
            } else {
                const errorData = await res.json();
                alert(`Error uploading site: ${errorData.message}`);
            }
        } catch (error) {
            console.error("Error during image upload or POST request:", error);
            alert("Error during site upload. Please try again.");
        }
    }

    // Create a custom icon using the imported pin icon
    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });


    // handles click on the map
    const handleMapClick = (e) => {
        setSiteLat(e.latlng.lat.toFixed(6));
        setSiteLong(e.latlng.lng.toFixed(6));
    };

    // Places the pointer on the map
    const PointerPlaced = () => {
        useMapEvents({
            click: handleMapClick,
        });

        return siteLat && siteLong ? (
            <Marker position={[parseFloat(siteLat), parseFloat(siteLong)]} icon={customIcon} />
        ) : null;
    };

    // Displays the upload form to the user.
    return (
        <form onSubmit={handleSubmit} className={"upload-form"}>
            <span>
                <div className="side1">
                    <input
                        type="text"
                        id="siteName"
                        name="siteName"
                        placeholder="Site Name"
                        onChange={(e) => setSiteName(e.target.value)}
                        required
                    />
                    <textarea
                        id="siteDescription"
                        name="siteDescription"
                        placeholder="Site Description"
                        onChange={(e) => setSiteDescription(e.target.value)}
                        required
                    />
                    <div className="site-upload-map" style={{width: '100%'}}>
                        <MapContainer className={"osm"} center={[57.149910, -2.093764]} zoom={13} style={{ height: '300px', width: '100%' }}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <PointerPlaced />
                        </MapContainer>
                    </div>
                    <div className="input-file">
                        <p>Upload Images</p>
                        <input
                            type="file"
                            onChange={(e) => {
                                setImgUpload(e.target.files);
                            }}
                            multiple
                            required
                        />
                    </div>

                    <div className="input-file">
                        <p>Upload Audio</p>
                        <input
                            type="file"
                            onChange={(e) => setAudioUpload(e.target.files[0])}
                            required
                        />
                    </div>
                </div>
                <input className="submitBtn" type="submit"/>
            </span>
        </form>
    )
}

export default SitesUploadModal;