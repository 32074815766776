/**
 * USETRAILCONTEXT.JS - Aberdeen Uncovered
 * Designed and Implemented by Team Bravo in 2024.
 */

// Import dependencies:
import { useContext } from 'react';
import { TrailContext } from '../context/TrailContext';

// This is a custom hook that allows us to use the TrailContext in any component.
export const useTrailContext = () => {
    const context = useContext(TrailContext);

    // If the context is not found, throw an error.
    if (!context) {
        throw Error('useTrailContext must be used inside a TrailContextProvider');
    }

    // Return the context.
    return context;
}