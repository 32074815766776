/**
 * SITES.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import {React, useState} from 'react';
import {Spinner} from "flowbite-react";
import { IoIosArrowForward } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import AUFooter from "../components/Footer";
import { useEffect } from 'react';
import { useSitesContext } from '../hooks/useSitesContext';
import '../styles/Sites.css';
import StarRating from '../components/StarRating.js'
import {useFeedbackContext} from "../hooks/useFeedbackContext";
import SiteCard from "../components/SiteCard";

/**
 * This page displays all sites available to the client to view.
 * TODO: Implement a search bar to filter sites by name, as well as other filtering options.
 */

function calculateDistance(destLat, destLong) {
    // This calculates the distance between the user's location and the location of a given site.
    // Reference: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLon = position.coords.longitude;

                    const R = 6371; // Radius of the Earth in kilometers
                    const dLat = (destLat - userLat) * (Math.PI / 180);
                    const dLon = (destLong - userLon) * (Math.PI / 180);
                    const a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(userLat * (Math.PI / 180)) *
                        Math.cos(destLat * (Math.PI / 180)) *
                        Math.sin(dLon / 2) *
                        Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    const distance = Math.round(((R * c) / 1.609) * 10) / 10; // Distance in miles
                    resolve(distance.toString());
                },
                (error) => {
                    console.error(`Error getting user's location: ${error.message}`);
                    reject(error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
            reject(new Error('Geolocation not supported'));
        }
    });
}

const Sites = () => {
    const { sites, dispatch } = useSitesContext();                      // Get store of sites from context
    const [distances, setDistances] = useState({});       // Store distances between user location & sites

    const [search, setSearch] = useState("");
    const [sortType, setSortType] = useState('AlphaAZ');

    const [rating, setRating] = useState(0);
    const {feedback, dispatch: fbDispatch} = useFeedbackContext();

    useEffect(() => {
        const fetchSites = async () => {
            // Fetches data from the API, storing the results inside the sites context.
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/sites');
            const json = await response.json();

            if (response.ok) {
                // Dispatch the sites to the context.
                dispatch({type: 'SET_SITES', payload: json});

                // For each site fetched, calculate the distance between the user and the site.
                json.forEach(site => {
                    calculateDistance(site.latitude, site.longitude)
                        // Note this function requires active access to the user's location. If this fails for any reason,
                        // we'll store the value of the distance as "X". We then pick up on this inside the JSX rendering
                        // block below. If there's an "X", we don't display that site's distance box at all.
                        .then(
                            distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: distance }))
                        )
                        .catch(
                            distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: "X" })),
                        );
                });
            }
        }

        fetchSites();
    }, [dispatch]);

    const filteredSites = sites && sites.filter(site => {
        return site.siteName.toLowerCase().includes(search.toLowerCase()) ||
            site.description.toLowerCase().includes(search.toLowerCase());
    });

    const sortSites = (type, a, b) => {
        if (type === 'AlphaAZ') {
            if (a.siteName < b.siteName) return -1;
        } else if (type === 'AlphaZA') {
            if (a.siteName > b.siteName) return -1;
        } else if (type === 'Nearest') {
            if (distances[a._id] === "X") return 1;
            if (distances[b._id] === "X") return -1;
            if (distances[a._id] < distances[b._id]) return -1;
        }
    }

    return (
        <div>
            <div className="subpage">
                <h1>Sites</h1>
                {
                    sites && (
                        <div className={"search-filter-bar"}>
                            <div>
                                <label>
                                    Sort by:
                                </label>
                                <select onChange={(e) => setSortType(e.target.value)}>
                                    <option value="AlphaAZ">Alphabetical Order (A-Z)</option>
                                    <option value="AlphaZA">Alphabetical Order (Z-A)</option>
                                    <option value="Nearest">Nearest to Me</option>
                                </select>
                            </div>
                            <input
                                type="text"
                                placeholder="Search for a site..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    )
                }
                <div data-testid="sites-container" className="sitesContainer">
                    {
                        // If sites haven't loaded yet, show the loading content message to inform user.
                        !sites && (
                            <div className={"loadingContent"}>
                                <Spinner size={'xl'} color={'pink'}/>
                                <p>Loading Content...</p>
                            </div>
                        )
                    }
                    {
                        // When sites have loaded, sort the list of sites in alphabetical order, then map each site to
                        // an individual card.
                        filteredSites && filteredSites.sort((a,b) => sortSites(sortType, a, b)).map((site) => (
                            <SiteCard site={site} />
                        ))
                    }
                </div>
            </div>
            <AUFooter />
        </div>
    );
}

export default Sites;