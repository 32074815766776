/**
 * USEFETCH.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2023.
 */

// Import dependencies for this hook:
import {useEffect, useState} from "react";

// This hook is used to fetch data from a URL and return the data, pending request, and error (if any).
// Reference: https://www.youtube.com/watch?v=qdCHEUaFhBk : The Net Ninja Full React Tutorial : Fetching Data with useEffect

const useFetch = (url) => {
    // We define three states: data, isPending, and error.
    const [data, setData] = useState(null);                     // Contains the actual data returned by the fetch request.
    const [isPending, setPending] = useState(true);    // Contains a boolean value indicating if the fetch request is pending.
    const [error, setError] = useState(null);                   // Contains an error message if the fetch request fails.

    useEffect(() => {
        fetch(url)
            .then(res => {
                if (!res.ok) {
                    // If the response could not return any form of data, the 'error' state is set.
                    setError("Could not fetch data for that resource.");
                    setPending(false);
                }
                return res.json();
            })
            .then((data) => {
                // When data is retrieved, we store the contents inside 'data'.
                setData(data);
                setPending(false);
                setError(null);
            })
            .catch((e) => {
                // If an exception occurs, we record the contents of the exception inside 'error'.
                setError(e.message);
                setPending(false);
                console.log(e.message);
            })
    }, [url]);

    return { data, isPending, error };                                  // Return an object containing data (if any), pending request, and error (if any).
}

export default useFetch;