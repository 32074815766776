/**
 * HOME.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { React } from 'react';
import { HiOutlineCamera } from "react-icons/hi";
import "../styles/Home.css";
import { useSitesContext } from '../hooks/useSitesContext';
import { useEffect, useState } from 'react';
import SiteCard from "../components/SiteCard";
import { IoIosArrowForward } from 'react-icons/io';
import AUFooter from '../components/Footer';
import {Spinner} from "flowbite-react";
import {useTrailContext} from "../hooks/useTrailContext";
import TrailCard from "../components/TrailCard";

/**
 * This page displays the homepage of the website.
 */

function calculateDistance(destLat, destLong) {
    // This calculates the distance between the user's location and the location of a given site.
    // Reference: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLon = position.coords.longitude;

                    const R = 6371; // Radius of the Earth in kilometers
                    const dLat = (destLat - userLat) * (Math.PI / 180);
                    const dLon = (destLong - userLon) * (Math.PI / 180);
                    const a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(userLat * (Math.PI / 180)) *
                        Math.cos(destLat * (Math.PI / 180)) *
                        Math.sin(dLon / 2) *
                        Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    const distance = Math.round(((R * c) / 1.609) * 10) / 10; // Distance in miles
                    resolve(distance.toString());
                },
                (error) => {
                    console.error(`Error getting user's location: ${error.message}`);
                    reject(error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
            reject(new Error('Geolocation not supported'));
        }
    });
}

const calculateRating = ( siteID ) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
        const json = await response.json();
        if (response.ok) {
            const siteFeedback = json.filter(f => f.siteID === siteID);
            let rating = 0;
            for (let i = 0; i < siteFeedback.length; i++) {
                rating += Number(siteFeedback[i].rating);
            }
            rating = Math.round(rating / siteFeedback.length);
            resolve(rating);
        } else {
            reject("Error fetching feedback");
        }
    });
}

const Home = () => {
    const { sites, dispatch } = useSitesContext();                      // Get store of sites from context
    const { trails, dispatch: trailDispatch } = useTrailContext();      // Get store of trails from context
    const [distances, setDistances] = useState({});       // Store distances between user location & sites

    const [hasLocation, setHasLocation] = useState(false);
    const [ratings, setRatings] = useState({});

    useEffect(() => {
        const fetchSites = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/sites');
            const json = await response.json();
    
            if (response.ok) {
                dispatch({ type: 'SET_SITES', payload: json });
    
                const distancesPromises = json.map(async (site) => {
                    try {
                        const distance = await calculateDistance(site.latitude, site.longitude);
                        setHasLocation(true);
                        return { ...site, distance };
                    } catch (error) {
                        console.error(`Error calculating distance for site ${site._id}: ${error.message}`);
                        setHasLocation(false);
                        return { ...site, distance: "X" };
                    }
                });
    
                Promise.all(distancesPromises)
                    .then(sitesWithDistances => {
                        setDistances(prevDistances => ({
                            ...prevDistances,
                            ...sitesWithDistances.reduce((acc, site) => ({ ...acc, [site._id]: site.distance }), {})
                        }));
                    })
                    .catch(error => {
                        console.error(`Error calculating distances: ${error.message}`);
                    });
            }
        }

        const fetchTrails = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/trails');
            const json = await response.json();

            if (response.ok) {
                trailDispatch({ type: 'SET_TRAILS', payload: json });

                const distancesPromises = json.map(async (trail) => {
                    try {
                        const distance = await calculateDistance(trail.startingLat, trail.startingLong);
                        setHasLocation(true);
                        return { ...trail, distance };
                    } catch (error) {
                        console.error(`Error calculating distance for site ${trail._id}: ${error.message}`);
                        setHasLocation(false);
                        return { ...trail, distance: "X" };
                    }
                });

                Promise.all(distancesPromises)
                    .then(trailsWithDistances => {
                        setDistances(prevDistances => ({
                            ...prevDistances,
                            ...trailsWithDistances.reduce((acc, trail) => ({ ...acc, ["TRAIL-"+trail._id]: trail.distance }), {})
                        }));
                    })
                    .catch(error => {
                        console.error(`Error calculating distances: ${error.message}`);
                    });
            }
        }

        fetchSites();
        fetchTrails();
    }, [dispatch]);

    return (
        <div className="homepage">
            {/* This contains the hero contents shown on the homepage. */}
            <div className="hero">
                {/* Aberdeen Uncovered Logo shown on mobile site - do not remove. */}
                <span className="mobileLogo">
                    <span className="upper">ABERDEEN</span>
                    <span className="lower">uncovered</span>
                </span>
                {/* Hero Background */}
                <img
                    src={"https://firebasestorage.googleapis.com/v0/b/abdnuncovered-2beac.appspot.com/o/hero%2Fhero_home.jpeg?alt=media&token=28d33627-7ce4-4dd3-b6fa-088005b082a7"}
                    alt={"Dunottar Castle, Stonehaven"}
                />
                {/* Hero Background Image Caption - required as part of Unsplash License - do not remove.*/}
                <div className="hImgCaption">
                    <span>
                        <HiOutlineCamera className="hCaptionIcon" style={{width: "25px", height: "25px", marginBottom: "15px"}} />
                        <p>
                            <a href="https://unsplash.com/@connormollison?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
                                Connor Mollison
                            </a> on <a href="https://unsplash.com/s/photos/aberdeen">
                                Unsplash
                            </a>
                        </p>
                    </span>
                </div>
                {/* Hero Text */}
                <h1>ABERDEEN'S HERITAGE, <b>UNCOVERED.</b></h1>
            </div>
            {/* HOMEPAGE CONTENTS BELOW THIS LINE */}
            <div className="subpage" style={{paddingTop: '60px'}}>
                <div className={"section"}>
                    <span>
                        <h2>Discover Aberdeen's Cultural Heritage</h2>
                    </span>
                    <p>
                        Uncover Aberdeen's cultural heritage through our visual and audio guided tours, offering a fresh,
                        new take on well-known sites around the famous Granite City.
                    </p>
                </div>
                {
                    !hasLocation ? (
                        <div className={"section"}>
                            <span>
                                <h2>Trails A-Z</h2>
                                <a href={"/maps/"} data-testid="see-all-trails-location-false">
                                    <button>SEE ALL</button>
                                    <IoIosArrowForward/>
                                </a>
                            </span>
                            <div className={'carousel'}>
                                {
                                    trails && trails.slice(0, 5).sort((a, b) => {if (a.trailName < b.trailName) return -1}).map((trail) => (
                                        <TrailCard trail={trail} key={trail._id} rating={0} />
                                    ))
                                }
                            </div>
                        </div>
                    ) : (
                        <div className={"section"}>
                            <span>
                                <h2>Trails Near Me</h2>
                                <a href={"/maps/"} data-testid="see-all-trails-location-true" >
                                    <button>SEE ALL</button>
                                    <IoIosArrowForward/>
                                </a>
                            </span>
                            <div className="carousel">
                                {
                                    trails && trails.filter(trail => distances["TRAIL-" + trail._id] !== "X")
                                        .sort((a, b) => parseFloat(distances[a._id]) - parseFloat(distances[b._id]))
                                        .reverse()
                                        .slice(0, 5)
                                        .map((trail) => (
                                            <TrailCard trail={trail} key={trail._id} rating={0} />
                                        ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    !hasLocation ? (
                        <div className={"section"}>
                            <span>
                                <h2>Sites A-Z</h2>
                                <a href={"/sites"} data-testid="see-all-sites-location-false">
                                    <button>SEE ALL</button>
                                    <IoIosArrowForward/>
                                </a>
                            </span>
                            <div className="carousel">
                                {sites && sites.sort((a, b) => {if (a.siteName < b.siteName) return -1}).slice(0, 10).map((site) => (
                                    <SiteCard site={site} key={site._id} rating={ratings[site._id]}/>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={"section"}>
                            <span>
                                <h2>Sites Near Me</h2>
                                <a href={"/sites"} data-testid="see-all-sites-location-true">
                                    <button>SEE ALL</button>
                                    <IoIosArrowForward/>
                                </a>
                            </span>
                            <div className="carousel">
                                {/* Sort the list of sites by distance to the user */}
                                {
                                    sites && sites.filter(site => distances[site._id] !== "X")
                                        .sort((a, b) => parseFloat(distances[a._id]) - parseFloat(distances[b._id]))
                                        .slice(0, 5)
                                        .map((site) => (
                                            <SiteCard data-testid="site-card" site={site} key={site._id}/>)
                                        )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <AUFooter/>
        </div>
    )
}

export default Home;