/**
 * MAPS.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2023.
 */

// Dependencies for this page:
import {React, useEffect, useRef} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Link } from "react-router-dom";
import { Alert, Button, Spinner } from "flowbite-react";
import { IoIosArrowForward } from "react-icons/io";
import {IoNavigate} from "react-icons/io5";
import {useSitesContext} from "../hooks/useSitesContext";
import {useTrailContext} from "../hooks/useTrailContext";
import '../styles/Maps.css';
import { useState } from 'react';
import {BiCurrentLocation} from "react-icons/bi";
import {FaMapMarkerAlt} from "react-icons/fa";
import {GiTrail} from "react-icons/gi";
import {MdAirlineStops} from "react-icons/md";

// Get images for icon as markers for sites and trails
function getIcon(iconSize, imageUrl, loc=false) {
    if (!loc) {
        const iconHtml = `<div class="circular-icon" style="width: ${iconSize}px; height: ${iconSize}px;"><img src="${imageUrl}" /></div>`;
        return L.divIcon({
            html: iconHtml,
            iconSize: [iconSize, iconSize],
            className: 'custom-marker-icon'
        });
    } else {
        return L.icon({
            iconUrl: require('../static/Icons/userLocMarker.png'),
            iconSize: [iconSize, iconSize],
        })
    }
}

function calculateDistance(destLat, destLong) {
    // This calculates the distance between the user's location and the location of a given site.
    // Reference: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLon = position.coords.longitude;

                    const R = 6371; // Radius of the Earth in kilometers
                    const dLat = (destLat - userLat) * (Math.PI / 180);
                    const dLon = (destLong - userLon) * (Math.PI / 180);
                    const a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(userLat * (Math.PI / 180)) *
                        Math.cos(destLat * (Math.PI / 180)) *
                        Math.sin(dLon / 2) *
                        Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    const distance = Math.round(((R * c) / 1.609) * 10) / 10; // Distance in miles
                    resolve(distance.toString());
                },
                (error) => {
                    console.error(`Error getting user's location: ${error.message}`);
                    reject(error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
            reject(new Error('Geolocation not supported'));
        }
    });
}

/**
 * This page displays a full-size map which can be moved about. The useFetch hook is used to obtain individual sites
 * from the database, and these are then displayed as markers on the map. Clicking on a marker will display a popup
 * from which the user can navigate to the site's details page.
 */

const Maps = () => {
    // Fetch sites from backend API.
    //const { data: trails, tError, tIsPending } = useFetch(process.env.REACT_APP_API_URL + "/api/trails");
    const { sites, dispatch: sitesDispatch } = useSitesContext();
    const { trails, dispatch: trailDispatch } = useTrailContext();
    const [distances, setDistances] = useState({});
    const [ currentLoc, setCurrentLoc ] = useState([]);
    const [ searchTerm, setSearchTerm ] = useState('');

    const getUserLoc = async () => {
        // Returns a user's location if geolocation is enabled in the browser.
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLoc([position.coords.latitude, position.coords.longitude]);
            })
        }
    }

    function RecenterButton() {
        const map = useMap();
      
        const recenterMap = (e) => {
            e.stopPropagation();
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    map.setView([pos.coords.latitude, pos.coords.longitude], 13.5);
                })
            } else {
                map.setView([57.149910, -2.093764], 13.5);
            }
        };
      
        return (
            <div className="recentre-button">
                <button onClick={recenterMap}>
                    <BiCurrentLocation style={{width: '40px', height: '40px'}} />
                </button>
            </div>
        );
    }

    useEffect(() => {
        const fetchSites = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/sites");
            const json = await response.json();
            if (response.ok) {
                sitesDispatch({type: 'SET_SITES', payload: json});

                // For each site fetched, calculate the distance between the user and the site.
                json.forEach(site => {
                    calculateDistance(site.latitude, site.longitude)
                        // Note this function requires active access to the user's location. If this fails for any reason,
                        // we'll store the value of the distance as "X". We then pick up on this inside the JSX rendering
                        // block below. If there's an "X", we don't display that site's distance box at all.
                        .then(
                            distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: distance }))
                        )
                        .catch(
                            distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: "X" })),
                        );
                });
            }
        }

        const fetchTrails = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/trails");
            const json = await response.json();
            if (response.ok) {
                trailDispatch({type: 'SET_TRAILS', payload: json});

                // For each site fetched, calculate the distance between the user and the site.
                json.forEach(trail => {
                    calculateDistance(trail.startingLat, trail.startingLong)
                        // Note this function requires active access to the user's location. If this fails for any reason,
                        // we'll store the value of the distance as "X". We then pick up on this inside the JSX rendering
                        // block below. If there's an "X", we don't display that site's distance box at all.
                        .then(
                            distance => setDistances(prevDistances => ({ ...prevDistances, ["T-" + trail._id]: distance }))
                        )
                        .catch(
                            distance => setDistances(prevDistances => ({ ...prevDistances, ["T-" + trail._id]: "X" })),
                        );
                });
            }
        }

        getUserLoc();
        fetchSites();
        fetchTrails();
    }, [sitesDispatch, trailDispatch]);

    setInterval(getUserLoc, 5000);

    const filteredSites = sites?.filter(site =>
        site.siteName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        site.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredTrails = trails?.filter(trail =>
        trail.trailName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        trail.trailDescription.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            {/* Shows alert banners when data is loading or if there's been an error loading data in. */}
            {
                !sites &&
                <Alert className={"globalAlerts"} color="info">
                    <Spinner color={"info"} style={{marginRight: '20px'}} />
                    <span className="font-medium">Loading Content...</span>
                </Alert>
            }
            
            {/* Map container containing map pins and links for user to access. */}
            <div className="map-container">
                <MapContainer className={"osm"} center={[57.149910, -2.093764]} zoom={13.5} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {   
                        // Show user location on map.tjtha
                        currentLoc[0] != null && <Marker position={currentLoc} icon={getIcon(30, 'userLocMarker', true)} />
                    }
                    {/* Take each site contained in 'sites' and give each element its own pin on the map. */}
                    {filteredSites && filteredSites.map((sites) => (
                        <Marker key={sites._id} position={[sites.latitude, sites.longitude]} icon={getIcon(40, sites.image)}>
                            <Popup>
                                {/* Each pin has its own popup, with a button to allow user to explore this pin. */}
                                <div className="map-popup">
                                    <img src={sites.image} alt={sites.image} />
                                    <h1>{sites.siteName}</h1>
                                    {/* This line below allows description to be truncated to 180 chars before '...' is added. */}
                                    <p>{(sites.description.length > 180) && sites.description.substring(0, 180) + "..."}</p>
                                    <p>{(sites.description.length <= 180) && sites.description}</p>
                                    <div className='site-attributes'>
                                        <div className='property'>
                                            <FaMapMarkerAlt/>
                                            {distances[sites._id] ? (
                                                <p>{distances[sites._id]} mi</p>
                                            ) : (
                                                <p>
                                                    <Spinner size={"xs"} color={'pink'}/>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <Link className="popup-link" to={`/sites/${sites._id}/`}>
                                        <p>Explore</p>
                                        <IoIosArrowForward size={"80%"}/>
                                    </Link>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                    {/* Take each trail contained in 'trails' and give each element its own pin on the map. */}
                    {filteredTrails && filteredTrails.map((trails) => (
                        <Marker key={trails._id} position={[trails.startingLat, trails.startingLong]} icon={getIcon(60, trails.trailImage)}>
                            <Popup>
                                {/* Each pin has its own popup, with a button to allow user to explore this pin. */}
                                <div className="map-popup">
                                    <img src={trails.trailImage} alt={trails.trailName}/>
                                    <h1>{trails.trailName}</h1>
                                    {/* This line below allows description to be truncated to 180 chars before '...' is added. */}
                                    <p>{(trails.trailDescription.length > 180) && trails.trailDescription.substring(0, 180) + "..."}</p>
                                    <p>{(trails.trailDescription.length <= 180) && trails.trailDescription}</p>
                                    <div className='site-attributes'>
                                        <div className='property'>
                                            <FaMapMarkerAlt />
                                            {distances["T-" + trails._id] ? (
                                                <p>{distances["T-" + trails._id]} mi</p>
                                            ) : (
                                                <p>
                                                    <Spinner size={"xs"} color={'pink'}/>
                                                </p>
                                            )}
                                        </div>
                                        <div className='property'>
                                            <MdAirlineStops />
                                            <p>{trails.sites.length}</p>
                                        </div>
                                    </div>
                                    <Link className="popup-link" to={`/trails/${trails._id}/`}>
                                        <p>Start</p>
                                        <IoNavigate size={"80%"}/>
                                    </Link>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                    <div className="search-bar-container">
                        <input
                            type="text"
                            placeholder="Search for trails or sites..."
                            className="search-bar"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <RecenterButton/>
                    </div>
                </MapContainer>

            </div>
        </div>
    );
}

export default Maps