import {React, useContext, useEffect, useState} from 'react';
import useFetch from "../../hooks/useFetch";
import {useAuthContext} from "../../hooks/useAuthContext";
import {FaUserPlus} from "react-icons/fa";
import {Modal} from "flowbite-react";

const ManageUsersModal = () => {
    const { user } = useAuthContext();
    const [ showNewUserModal, setShowNewUserModal ] = useState(false);
    const [ users, setUsers ] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const res = await fetch(process.env.REACT_APP_API_URL + "/api/users/users/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: user.token,
                    accountType: user.accountType
                })
            });
            if (res.status === 200) {
                const data = await res.json();
                setUsers(data);
            }
        }
        getUsers();
    }, [user])

    return (
        <div className="manage-users-modal">
            {
                users && (
                    <table>
                        <thead>
                        <tr>
                            <th>Username</th>
                            <th>Account Type</th>
                            <th>Account Options</th>
                        </tr>
                        </thead>
                        <tbody>
                            {users.sort((a, b) => {if (a.username < b.username) return -1}).map((userRow, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{userRow.username}</td>
                                        <td>{(userRow.accountType === "admin") ? "Administrator" : "Normal"}</td>
                                        <td>
                                            {
                                                (userRow.username !== user.username) ? (
                                                    <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                                        <button
                                                            onClick={() => alert("Feature Currently in Development")}
                                                        >
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <p><i>You cannot make changes to your own account here.</i></p>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )
            }
        </div>
    )
}

export default ManageUsersModal;