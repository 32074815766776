/**
 * CMSLANDING.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { React, useEffect, useState } from 'react';
import { useAuthContext } from "../../hooks/useAuthContext";
import AUFooter from "../../components/Footer";
import {FaArrowRight, FaEye, FaRegStar, FaStar, FaTrashAlt, FaUserPlus, FaUsers} from "react-icons/fa";
import useFetch from "../../hooks/useFetch";
import {FaPencil} from "react-icons/fa6";
import {useSitesContext} from "../../hooks/useSitesContext";
import {MdAirlineStops, MdOutlineAddAPhoto, MdOutlineFeedback} from "react-icons/md";
import {Alert, Button, Modal} from "flowbite-react";
import SitesUploadModal from "./SitesUploadModal";
import TrailUploadModal from "./TrailUploadModal";
import '../../styles/CMS_Landing.css';
import {HiInformationCircle, HiOutlineExclamationCircle} from "react-icons/hi";
import {useTrailContext} from "../../hooks/useTrailContext";
import { getStorage, ref, deleteObject } from 'firebase/storage';
import {Link, useNavigate} from 'react-router-dom';
import SitesEditModal from './SitesEditModal';
import TrailEditModal from './TrailEditModal';
import AppFeedbackModal from "./AppFeedbackModal";
import ContentFeedbackModal from "./ContentFeedbackModal";
import {RiLockPasswordLine} from "react-icons/ri";
import './CMSFunctions.js';
import {handleAddUser, handleChangePassword} from "./CMSFunctions";
import ManageUsersModal from "./ManageUsersModal";

/**
 * This page displays the landing page to the user after logging in.
 */

const CMSLanding = () => {
    const { user } = useAuthContext();
    const [ feedback, setFeedback ] = useState([]);
    const { sites, dispatch: siteDispatch } = useSitesContext();
    const { trails, dispatch: trailDispatch } = useTrailContext();
    //const { data: trails, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + "/api/trails");

    // Modal states
    const [showNewTrailModal, setShowNewTrailModal] = useState(false);
    const [showNewSiteModal, setShowNewSiteModal] = useState(false);
    const [showDeleteSiteModal, setShowDeleteSiteModal] = useState(false);
    const [showDeleteTrailModal, setShowDeleteTrailModal] = useState(false);
    const [showEditSiteModal, setShowEditSiteModal] = useState(false);
    const [showEditTrailModal, setShowEditTrailModal] = useState(false);
    const [showContentFbModal, setShowContentFbModal] = useState(false);
    const [showAppFbModal, setShowAppFbModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showManageUsersModal, setShowManageUsersModal] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showHPUpload, setShowHPUpload] = useState(false);

    const [deleteSiteId, setDeleteSiteId] = useState(null);
    const [deleteTrailId, setDeleteTrailId] = useState(null);

    // Form States
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [accountType, setAccountType] = useState("normal");

    useEffect(() => {
        const fetchSites = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/sites/');
            const json = await response.json();
            if (response.ok) {
                siteDispatch({ type: 'SET_SITES', payload: json });
            }
        }

        const fetchTrails = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/trails/');
            const json = await response.json();
            if (response.ok) {
                trailDispatch({ type: 'SET_TRAILS', payload: json });
            }
        }

        const fetchFeedback = async () => {
            try{
                const response = await fetch(process.env.REACT_APP_API_URL + "/api/feedback/");
                if (!response.ok) {
                    throw new Error('Failed to fetch feedback');
                }
                const feedbackData = await response.json();
                setFeedback(feedbackData);
            } catch (error) {
                console.error('Error fetching feedback: ', error)
            }
        };

        fetchSites();
        fetchTrails();
        fetchFeedback();
    }, [trailDispatch, siteDispatch]);

    const handleDeleteTrail = (trail) => {
        const url = process.env.REACT_APP_API_URL + "/api/trails/" + trail._id;
        const storage = getStorage();
        const trailRef = ref(storage, trail.trailImage);
        deleteObject(trailRef)
            .then(() => {
                fetch(url, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((res)=>{
                    if(!res.ok){
                        throw new Error("Failed to delete");
                    }
                    if (res.ok) {
                        trailDispatch({type: "DELETE_TRAIL", payload: trail._id});
                        alert("Trail deleted!");
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            });
    }

    const removeSiteFromTrail = (siteId) => {
        // This function removes a given site ID from all trails on the server - used as part of the handleDelete()
        // method.
        const url = process.env.REACT_APP_API_URL + "/api/trails/";
        fetch(url)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Cannot delete site from trail - unable to fetch trails from server.");
                }
                return res.json();
            })
            .then((trails) => {
                trails.forEach((trail) => {
                    const index = trail.sites.indexOf(siteId);
                    if (index > -1) {
                        trail.sites.splice(index, 1);
                        fetch(url + trail._id, {
                            method: 'PATCH',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(trail)
                        })
                            .then((res) => {
                                if (!res.ok) {
                                    throw new Error("Failed to remove site from trail.");
                                }
                            })
                    }
                })
            })
            .catch((error) => {
                console.error("Failed to remove site from trail: " + error);
            })
    }

    const handleDeleteSite = async (site) => {
        const url = process.env.REACT_APP_API_URL + "/api/sites/" + site._id;

        // TODO: Look for where site is inside trails and remove it from there so as to not impact the trail.
        await removeSiteFromTrail(site._id);

        const storage = getStorage();
        const siteRef = ref(storage, site.image);
        deleteObject(siteRef)
            .then(() => {
                const audioRef = ref(storage, site.audio);
                deleteObject(audioRef)
                    .then(() => {
                        fetch(url, {
                            method: 'DELETE',
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }).then((res) => {
                            if (!res.ok) {
                                throw new Error("Failed to delete");
                            }
                            if (res.ok) {
                                siteDispatch({type: "DELETE_SITE", payload: site._id});
                                alert("Site deleted!");
                            }
                        }).catch((e) => {
                            console.log(e);
                        })
                    })
            });
    }

    return (
        <div>
            <div className="subpage">
                {/* USER MANAGEMENT MODALS - THESE ARE 'PAGES' IN THEIR OWN RIGHT AND SHOULD BE KEPT HERE IN STEAD OF SPLIT INTO THEIR OWN COMPONENTS. */}

                <Modal position={"center"} dismissible size='4xl' show={showHPUpload} onClose={() => setShowHPUpload(false)}>
                    <Modal.Header>
                        Change Home Photo
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            Feature currently in development.
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='2xl' show={showChangePasswordModal} onClose={() => setShowChangePasswordModal(false)}>
                    <Modal.Header>
                        Change Password
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <form className={"upload-form"} onSubmit={(e) => {
                                e.preventDefault();
                                handleChangePassword(e, user, oldPassword, newPassword, confirmPassword)
                                    .then((res) => {
                                        alert(res);
                                        setShowChangePasswordModal(false);
                                    })
                                    .catch((error) => {
                                        alert(error);
                                    });
                            }}>
                                <span>
                                    <input
                                        type="password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        placeholder="Current Password"
                                        onChange={(e) => {
                                            setOldPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder="New Password"
                                        onChange={(e) => {
                                            setNewPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm New Password"
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <input
                                        type="submit"
                                        className="cta"
                                        style={{width: '150px', alignSelf: 'center'}}
                                        value="Update"
                                    />
                                </span>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='2xl' show={showCreateUserModal}
                       onClose={() => setShowCreateUserModal(false)}>
                    <Modal.Header>
                        Create New User
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <form className={"upload-form"} onSubmit={(e) => {
                                e.preventDefault();
                                handleAddUser(e, user, username, password, accountType)
                                    .then((res) => {
                                        alert(res);
                                        setShowCreateUserModal(false);
                                    })
                                    .catch((error) => {
                                        alert(error);
                                    });
                            }}>
                                <span>
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Username"
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                        }}
                                        required
                                    />
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <select
                                        id="accountType"
                                        name="accountType"
                                        onChange={(e) => {
                                            setAccountType(e.target.value)
                                        }}
                                        defaultValue={"user"}
                                        required
                                    >
                                        <option value="admin">Administrator</option>
                                        <option value="user">Normal</option>
                                    </select>
                                    {
                                        (accountType === "admin") && (
                                            <Alert color={"warning"} icon={HiInformationCircle}>
                                                <span className="font-medium" style={{display: "inline-block"}}>Caution!</span> Administrators have full access to the Content Manager, and can manage both the users who access this system, and the content contained on the site.
                                                If you're looking to add a user that only needs access to the content manager, select the "Normal" option.
                                            </Alert>
                                        )
                                    }
                                    <input
                                        type="submit"
                                        className="cta"
                                        style={{width: '150px', alignSelf: 'center'}}
                                        value="Add New User"
                                    />
                                </span>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showManageUsersModal}
                       onClose={() => setShowManageUsersModal(false)}>
                    <Modal.Header>
                        Manage Users
                    </Modal.Header>
                    <Modal.Body>
                        <button onClick={() => setShowCreateUserModal(true)} className="cta"
                                style={{width: '175px', marginBottom: '10px'}}>
                            <FaUserPlus/>
                            <p>Create New User</p>
                        </button>
                        <ManageUsersModal/>
                    </Modal.Body>
                </Modal>

                {/* CMS MODAL PAGES - THESE ARE 'PAGES' IN THEIR OWN RIGHT AND SHOULD BE KEPT HERE INSTEAD OF SPLIT INTO THEIR OWN COMPONENTS. */}

                <Modal position={"center"} dismissible size='6xl' show={showNewTrailModal}
                       onClose={() => setShowNewTrailModal(false)}>
                    <Modal.Header>
                        Create New Trail
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <TrailUploadModal/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showEditTrailModal}
                       onClose={() => setShowEditTrailModal(false)}>
                    <Modal.Header>
                        Edit Trail
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <TrailEditModal trail={{deleteTrailId}}/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showNewSiteModal}
                       onClose={() => setShowNewSiteModal(false)}>
                    <Modal.Header>
                        Create New Site
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <SitesUploadModal />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showEditSiteModal} onClose={() => setShowEditSiteModal(false)}>
                    <Modal.Header>
                        Edit Site
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <SitesEditModal site={{deleteSiteId}} />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showAppFbModal} onClose={() => setShowAppFbModal(false)}>
                    <Modal.Header>
                        App Feedback
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <AppFeedbackModal />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal position={"center"} dismissible size='6xl' show={showContentFbModal} onClose={() => setShowContentFbModal(false)}>
                    <Modal.Header>
                        Content Feedback
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <ContentFeedbackModal />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showDeleteSiteModal} size="md" onClose={() => setShowDeleteSiteModal(false)} popup>
                    <Modal.Header />
                    <Modal.Body>
                        <div className="text-center">
                            <HiOutlineExclamationCircle style={{width: '50px', height: '50px'}} className="mx-auto mb-4 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to delete this site?
                            </h3>
                            <p className="mb-5 text-sm font-normal text-gray-500 dark:text-gray-400">
                                The site will no longer be available to users to view. Trails which contain this site will also be affected.
                            </p>
                            <div className="flex justify-center gap-4">
                                <Button color="failure" onClick={() => {
                                    handleDeleteSite(deleteSiteId);
                                    setShowDeleteSiteModal(false)
                                }}>
                                    Yes, I'm sure
                                </Button>
                                <Button color="gray" onClick={() => {
                                    setShowDeleteSiteModal(false);
                                    setDeleteSiteId(null);
                                }}>
                                    No, cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showDeleteTrailModal} size="md" onClose={() => setShowDeleteTrailModal(false)} popup>
                    <Modal.Header />
                    <Modal.Body>
                        <div className="text-center">
                            <HiOutlineExclamationCircle style={{width: '50px', height: '50px'}} className="mx-auto mb-4 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to delete this trail?
                            </h3>
                            <p className="mb-5 text-sm font-normal text-gray-500 dark:text-gray-400">
                                The trail will no longer be available to users to view. Individual sites will not be affected.
                            </p>
                            <div className="flex justify-center gap-4">
                                <Button color="failure" onClick={() => {
                                    handleDeleteTrail(deleteTrailId);
                                    setShowDeleteTrailModal(false)
                                }}>
                                    Yes, I'm sure
                                </Button>
                                <Button color="gray" onClick={() => {
                                    setShowDeleteTrailModal(false);
                                    setDeleteTrailId(null);
                                }}>
                                    No, cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                
                {/* ------------------------------------------------------------------------------------------------------------ */}

                {/* START OF CONTENT MANAGEMENT SYSTEM DASHBOARD */}
                <div className="content-manager">
                    <span className="cms-header">
                        <h1>Welcome, {user.username}!</h1>
                        <div className="account-controls">
                            <button className={"cta"} style={{width: '220px'}}
                                    onClick={() => setShowHPUpload(true)}>
                                <MdOutlineAddAPhoto/>
                                <p>Change Home Photo</p>
                            </button>
                            <button className={"cta"} style={{width: '200px'}}
                                    onClick={() => setShowChangePasswordModal(true)}>
                                <RiLockPasswordLine/>
                                <p>Change Password</p>
                            </button>
                            {
                                user.accountType === "admin" && (
                                    <button className={"cta"} style={{width: '175px'}}
                                            onClick={() => setShowManageUsersModal(true)}>
                                        <FaUsers/>
                                        <p>Manage Users</p>
                                    </button>
                                )
                            }
                        </div>
                    </span>
                    <div className="cms-container">
                        <div className="list-container">
                            <span>
                                <h2>Published Trails</h2>
                                <button className={"link"} onClick={() => setShowNewTrailModal(true)}>
                                    <p>Create New</p>
                                    <FaArrowRight/>
                                </button>
                            </span>
                            <div className="trail-list">
                                {trails && trails.map((trail) => {
                                    return (
                                        <div key={trail._id} className="trail-card">
                                            <img src={trail.trailImage} alt={trail.trailName}/>
                                            <span>
                                                <div className={"trail-details"}>
                                                    <h3>{trail.trailName}</h3>
                                                    <p>{(trail.trailDescription.length > 140) ? trail.trailDescription.substring(0, 140) + "..." : trail.trailDescription}</p>
                                                </div>
                                                <div className={"trail-controls"}>
                                                    <div className={"trail-feedback"}>
                                                        <div className={"attribute"}>
                                                            <FaStar/>
                                                            <p>{feedback.filter((fb) => fb.trailID === trail._id).reduce((acc, curr) => Number(acc) + Number(curr.rating), 0) / feedback.filter((fb) => fb.trailID === trail._id).length} / 5</p>
                                                        </div>
                                                        <div className={"attribute"}>
                                                            <MdAirlineStops/>
                                                            <p>{trail.sites.length}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"trail-edit"}>
                                                        <button className="btn" onClick={(e) => {
                                                                setDeleteTrailId(trail);
                                                                setShowEditTrailModal(true);
                                                            }}>
                                                            <FaPencil/>
                                                            <p>Edit</p>
                                                        </button>
                                                        <button
                                                            className="btn"
                                                            onClick={(e) => {
                                                                setDeleteTrailId(trail);
                                                                setShowDeleteTrailModal(true);
                                                            }}
                                                        >
                                                            <FaTrashAlt />
                                                            <p>Delete</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="cms-container-vertical">
                            <div className="list-container" style={{width: '100%', height: '50%'}}>
                                <span>
                                    <h2>Published Sites</h2>
                                    <button className={"link"} onClick={() => setShowNewSiteModal(true)}>
                                        <p>Create New</p>
                                        <FaArrowRight/>
                                    </button>
                                </span>
                                <div className="site-list">
                                    {
                                        sites && sites.map((site) => (
                                            <div key={site._id} className="site-card">
                                                <img src={site.image} alt={site.siteName}/>
                                                <h1>{(site.siteName.length > 24) ? site.siteName.substring(0, 24) + "..." : site.siteName}</h1>
                                                <div className="site-controls">
                                                    <button className="btn" onClick={(e) => {
                                                        setDeleteSiteId(site);
                                                        setShowEditSiteModal(true);
                                                    }}>
                                                        <FaPencil/>
                                                    </button>
                                                    <button className="btn" onClick={(e)=> {
                                                        setDeleteSiteId(site);
                                                        setShowDeleteSiteModal(true);
                                                    }}>
                                                        <FaTrashAlt/>
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="engagement">
                                <span>
                                    <h2>User Engagement</h2>
                                    <button className={"link"} onClick={() => setShowContentFbModal(true)}>
                                        <p>View All</p>
                                        <FaArrowRight/>
                                    </button>
                                </span>
                                <div className='feedback-container'>
                                    <div className='feedback-card'>
                                        <h2>Average Site Rating</h2>
                                        <FaRegStar className="feedback-icon"/>
                                        <p>
                                            {feedback.filter((fb) => fb.feedbackType === "Site").reduce((acc, curr) => Number(acc) + Number(curr.rating), 0) / feedback.filter((fb) => fb.feedbackType === "Site").length} out of 5
                                        </p>
                                    </div>
                                    <div className='feedback-card'>
                                        <h2>Average Trail Rating</h2>
                                        <FaRegStar className="feedback-icon" />
                                        <p>
                                            {feedback.filter((fb) => fb.feedbackType === "Trail").reduce((acc, curr) => Number(acc) + Number(curr.rating), 0) / feedback.filter((fb) => fb.feedbackType === "Trail").length} out
                                            of 5
                                        </p>
                                    </div>
                                    <button className='feedback-card' onClick={() => setShowAppFbModal(true)}>
                                        <h2>App Feedback</h2>
                                        <MdOutlineFeedback className="feedback-icon"/>
                                        <p>
                                            {feedback.filter((fb) => fb.feedbackType === "App").filter((fb) => fb.comment !== "").length} comments
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AUFooter/>
        </div>

    );
}

export default CMSLanding;