/**
 * NOTFOUND.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { React } from 'react';
import { SiAlltrails } from "react-icons/si";

/**
 * This is a generic 404 page that is displayed to the user whenever they navigate to a nonexistent page on the site.
 */

const NotFound = () => {
    return (
        <div>
            <div className="subpage">
                <h1>Error 404</h1>
                <p>Sorry! The page you're looking for doesn't exist.</p>
                <SiAlltrails style={{ marginLeft: "auto", marginRight: "auto", maxHeight: "200px", maxWidth: "200px" }} size={"60%"} color={"rgba(67,42,71,0.15)"} />
            </div>
        </div>
    )
}

export default NotFound;