/**
 * TRAILCONTEXT.JS - ABERDEEN UNCOVERED
 * Designed and Implemented by Team Bravo in 2024.
 */

// Import dependencies:
import { createContext, useReducer } from 'react';

/**
 * This context is used to retain local state of the individual trails loaded in. We do this to prevent the application
 * having to constantly make requests to the backend, therefore reducing the load on the backend.
 */

export const TrailContext = createContext();

// Updates the state of the trails context. Accepts two parameters. 'state' is the current state of the context, and
// 'action' is the action to be performed on the state.

export const trailReducer = (state, action) => {
    switch (action.type) {
        // These are the list of VALID actions that can be performed on the state. We can add others here if needed.
        // The payload is the data that is passed to the reducer to be added to the state.
        case 'SET_TRAILS':
            return {
                // This is like saying variable x = some value.
                // Set trails to be whatever the contents of the action payload are.
                trails: action.payload
            }
        case 'CREATE_TRAIL':
            return {
                // This is like appending some value X to the end of an array. We add the action payload to whatever's
                // already stored in the state.trails object.
                trails: [action.payload, ...state.trails]
            }
        case 'DELETE_TRAIL':
            return {
                // This removes the trail from the state.trails object, by filtering out the trail with the ID of the
                // action payload.
                trails: state.trails.filter((w) => w._id !== action.payload._id)
            }
        default:
            // This is the default action when an action isn't selected. It just returns the state as it is.
            return state
    }
}

export const TrailContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(trailReducer, {
        trails: null
    })

    // We return the following element to the application, a context provider. Any element wrapped between these tags
    // (i.e. the {children}) will have access to this context's state and dispatch functions.
    return (
        <TrailContext.Provider value={{ ...state, dispatch }}>
            { children }
        </TrailContext.Provider>
    )
}