/**
 * TRAILDETAIL.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import {React, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import useFetch from "../hooks/useFetch";
import AUFooter from "../components/Footer";
import {useMediaQuery} from "react-responsive";
import {Spinner} from "flowbite-react";
import {
    IoIosArrowForward,
    IoIosInformation,
    IoIosInformationCircleOutline,
    IoIosSend,
    IoMdHeartEmpty
} from "react-icons/io";
import {useSitesContext} from "../hooks/useSitesContext";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Routing from "../components/RoutingMachine";
import '../styles/TrailDetail.css';
import {FaArrowLeft, FaArrowRight, FaPause, FaPlay, FaVolumeMute} from "react-icons/fa";
import {Progress} from 'flowbite-react';
import {HiOutlinePlayPause} from "react-icons/hi2";
import {IoStarOutline, IoVolumeMute} from "react-icons/io5";
import {PiPath} from "react-icons/pi";
import {Modal} from 'flowbite-react';
import {GiTrail} from "react-icons/gi";
import {MdOutlineFeedback} from "react-icons/md";
import StarRating from "../components/StarRating";

/**
 * This page displays the details of a given trail. On desktop, the user will be shown a PREVIEW of the trail. On
 * mobile, the trail details page runs like a virtual tour guide.
 */

// Get icon for map markers.
function getIcon(_iconSize, iconType) {
    return L.icon({
        iconUrl: require('../static/Icons/' + iconType + '.png'),
        iconSize: [_iconSize, _iconSize],
    })
}

const TrailDetail = () => {
    const { trailID } = useParams();
    // const { sites, dispatch: siteDispatch } = useSitesContext();
    const [ currentLoc, setCurrentLoc ] = useState([]);
    const [ currentSiteIndex, setCurrentSiteIndex ] = useState(0);
    const { data: trail, error: tError, isPending: tPending } = useFetch(process.env.REACT_APP_API_URL + "/api/trails/" + trailID);
    const { data: sites, error: sError, isPending: sPending } = useFetch(process.env.REACT_APP_API_URL + "/api/sites");
    const [ showInfoModal, setShowInfoModal ] = useState(false);
    const [ showMapModal, setShowMapModal ] = useState(false);
    const [ showFeedbackModal, setShowFeedbackModal ] = useState(false);
    const [ avgRating, setAvgRating ] = useState(0);

    // Feedback form state.
    const [ rating, setRating ] = useState(0);
    const [ comment, setComment ] = useState('');

    // Submit feedback to the backend.
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Check if user has entered a rating and feedback before submitting.
            if (rating === 0) {
                alert('Please enter a rating before submitting.');
                return;
            }
            // Check if user has entered a comment before submitting.
            if (comment.trim() === '') {
                alert('Please enter feedback before submitting.');
                return;
            }
            // Create new feedback object.
            const newFeed = {
                feedbackType: 'Trail',
                rating: rating,
                siteID: '',
                trailID: trailID,
                comment: comment
            };

            const res = await fetch(process.env.REACT_APP_API_URL + "/api/feedback/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newFeed)
            });

            if (res.ok) {
                alert('Feedback recorded!');
                setRating(0);
                setComment('');
                setShowFeedbackModal(false);
            } else {
                alert('Feedback failed to record. Please try again.');
            }
        } catch (error) {
            alert(error);
        }
    };

    let waypoints = [];

    const getUserLoc = async () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLoc([position.coords.latitude, position.coords.longitude]);
            })
        }
    }

    const calculateRating = ( trailID ) => {
        return new Promise(async (resolve, reject) => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
            const json = await response.json();
            if (response.ok) {
                const trailFeedback = json.filter(f => f.trailID === trailID);
                let rating = 0;
                for (let i = 0; i < trailFeedback.length; i++) {
                    rating += Number(trailFeedback[i].rating);
                }
                rating = Math.round(rating / trailFeedback.length);
                if (isNaN(rating)) {
                    rating = 0;
                }
                resolve(rating);
            } else {
                reject("Error fetching feedback");
            }
        });
    }

    useEffect(() => {
        calculateRating(trailID)
            .then((rating) => {
                setAvgRating(rating);
            })

        return () => {
            waypoints = [];
            getUserLoc();
        }
    }, [trailID]);

    setTimeout(getUserLoc, 2000);

    // isMobile determines whether we display trail details or trail preview.
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    if (isMobile) {
        // Display the MOBILE TRAIL tour guide.
        return (
            <div>
                {!trail && (
                    <div className={"loadingContent"}>
                        <Spinner size={'xl'} color={'pink'}/>
                        <p>Loading Content...</p>
                    </div>
                )}
                {trail && (
                    <div>
                        <Modal position={"center"} style={{zIndex: '1000'}} dismissible show={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}>
                            <Modal.Header>
                                Leave Feedback
                            </Modal.Header>
                            <Modal.Body>
                                <div className="space-y-6">
                                    <form onSubmit={handleSubmit} className="formCard">
                                        <label className='label2'>How would you rate your experience on this trail?</label>
                                        <span>
                                    <StarRating rating={rating} enabled={true} setRating={setRating}/>
                                </span>
                                        <label className='label1'>Do you have any additional comments about this trail?</label>
                                        <textarea value={comment} onChange={(e) => setComment(e.target.value)}
                                                  className='textbox'/>
                                        <button type="submit" onClick={handleSubmit} className='cta'>
                                            <p>Submit</p>
                                            <IoIosSend/>
                                        </button>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {
                            sites && trail.sites.map((trailSite, index) => {
                                if (index === currentSiteIndex) {
                                    if (sites) {
                                        const site = sites.find((site) => site._id === trailSite);
                                        if (site) {
                                            return (
                                                <div key={site._id} className="mobileTrail">
                                                    <Modal position={"center"} dismissible show={showInfoModal} onClose={() => setShowInfoModal(false)}>
                                                        <Modal.Header>{site.siteName}</Modal.Header>
                                                        <Modal.Body>
                                                            <div className="space-y-6">
                                                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                                                    {site.description}
                                                                </p>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                    <Modal position={"center"} dismissible show={showMapModal} onClose={() => setShowMapModal(false)}>
                                                        <Modal.Header>
                                                            Trail Map
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className={"trailMap"}>
                                                                <MapContainer
                                                                    className={"osm"}
                                                                    center={[57.1497, -2.0943]}
                                                                    zoom={12}
                                                                    style={{height: '100%', width: '100%', borderRadius: '15px'}}
                                                                >
                                                                    <TileLayer
                                                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                    />
                                                                    {
                                                                        currentLoc[0] != null && <Marker position={currentLoc}
                                                                                                         icon={getIcon(30, 'userLocMarker')}/>
                                                                    }
                                                                    {
                                                                        trail.sites.map((trailSite, index) => {
                                                                            if (sites) {
                                                                                index = index + 1;
                                                                                const site = sites.find((site) => site._id === trailSite);
                                                                                waypoints.push([site.latitude, site.longitude]);
                                                                                if (index === 1) {
                                                                                    return (
                                                                                        <Marker key={sites._id}
                                                                                                position={[site.latitude, site.longitude]}
                                                                                                icon={getIcon(60, 'startMarker')}/>
                                                                                    )
                                                                                } else if (index === trail.sites.length) {
                                                                                    return (
                                                                                        <Marker key={sites._id}
                                                                                                position={[site.latitude, site.longitude]}
                                                                                                icon={getIcon(60, 'endMarker')}/>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <Marker key={sites._id}
                                                                                                position={[site.latitude, site.longitude]}
                                                                                                icon={getIcon(40, 'siteMarker')}/>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    <Routing id={"trailPath"} waypoints={waypoints}/>
                                                                </MapContainer>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                    <div className="siteOnTrailMobile">
                                                        <img src={site.image[0]} alt={site.siteName}/>
                                                        <div className="trailPagination">
                                                            <Progress
                                                                progress={((currentSiteIndex + 1) / trail.sites.length) * 100}
                                                                color={'pink'} style={{
                                                                width: '90%',
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto'
                                                            }}/>
                                                            <div id='pagination'>
                                                                {currentSiteIndex > 0 ? (
                                                                    <button onClick={() => {
                                                                        if (currentSiteIndex === 0) {
                                                                            alert("You are at the start of the trail.");
                                                                        } else {
                                                                            setCurrentSiteIndex(currentSiteIndex - 1)
                                                                        }
                                                                    }}>
                                                                        <FaArrowLeft size={'25px'}/>
                                                                    </button>
                                                                ) : (
                                                                    <button disabled>
                                                                        <FaArrowLeft size={'25px'} opacity={'50%'}/>
                                                                    </button>
                                                                )}
                                                                <h2>{site.siteName}</h2>
                                                                {currentSiteIndex < trail.sites.length - 1 ? (
                                                                    <button onClick={() => {
                                                                        if (currentSiteIndex === trail.sites.length - 1) {
                                                                            alert("You are at the end of the trail.");
                                                                        } else {
                                                                            setCurrentSiteIndex(currentSiteIndex + 1)
                                                                        }
                                                                    }}>
                                                                        <FaArrowRight size={'25px'}/>
                                                                    </button>
                                                                ) : (
                                                                    <button disabled>
                                                                        <FaArrowRight size={'25px'} opacity={'50%'}/>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="trailControls">
                                                            {
                                                                site.audio ? (
                                                                    <div className={"audioControls"}>
                                                                        <audio id='trailAudio' src={site.audio} autoPlay/>
                                                                        <button onClick={() => {
                                                                            const audio = document.getElementById('trailAudio');
                                                                            if (audio.paused) {
                                                                                audio.play();
                                                                            } else {
                                                                                audio.pause();
                                                                            }
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                <HiOutlinePlayPause style={{
                                                                                    width: '30px',
                                                                                    height: '30px'
                                                                                }}/>
                                                                                <p style={{fontSize: '0.7rem'}}>Play/Pause</p>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <IoVolumeMute
                                                                            style={{width: '30px', height: '30px'}}/>
                                                                        <p style={{fontSize: '0.7rem'}}>No Audio</p>
                                                                    </div>
                                                                )
                                                            }
                                                            <button onClick={() => {
                                                                setShowInfoModal(true);
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <IoIosInformationCircleOutline
                                                                        style={{width: '30px', height: '30px'}}/>
                                                                    <p style={{fontSize: '0.7rem'}}>Site Info</p>
                                                                </div>
                                                            </button>
                                                            <button onClick={() => {
                                                                setShowMapModal(true);
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <PiPath
                                                                        style={{width: '30px', height: '30px'}}/>
                                                                    <p style={{fontSize: '0.7rem'}}>Trail Map</p>
                                                                </div>
                                                            </button>
                                                            <button onClick={() => {
                                                                setShowFeedbackModal(true);
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <MdOutlineFeedback
                                                                        style={{width: '30px', height: '30px'}}/>
                                                                    <p style={{fontSize: '0.7rem'}}>Feedback</p>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    </div>
                )}
            </div>
        )
    } else {
        // Display the DESKTOP TRAIL preview.
        return (
            <div>
                <Modal position={"center"} style={{zIndex: '1000'}} dismissible show={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}>
                    <Modal.Header>
                        Leave Feedback
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <form onSubmit={handleSubmit} className="formCard">
                                <label className='label2'>How would you rate your experience on this trail?</label>
                                <span>
                                    <StarRating rating={rating} enabled={true} setRating={setRating}/>
                                </span>
                                <label className='label1'>Do you have any additional comments about this trail?</label>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)}
                                          className='textbox' placeholder={"Did a particular site interest you...?"}/>
                                <button type="submit" onClick={handleSubmit} className='cta'>
                                    <p>Submit</p>
                                    <IoIosSend/>
                                </button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className={"subpage"}>
                    {!trail && (
                        <div className={"loadingContent"}>
                            <Spinner size={'xl'} color={'pink'}/>
                            <p>Loading Content...</p>
                        </div>
                    )}
                    {trail && (
                        <div>
                        <h1>{trail.trailName}</h1>
                            <span className="trailPage">
                                <div className="trailInfo">
                                    <div className="trailHeader">
                                        <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                                            <h2>Trail Description</h2>
                                            <div className={"trailRating"}>
                                                <div className="property">
                                                    <IoStarOutline/>
                                                    <p>{avgRating} / 5</p>
                                                </div>
                                                <button style={{width: '130px'}} className={"cta"} onClick={() => {
                                                    setShowFeedbackModal(true);
                                                }}>
                                                    <p>Feedback</p>
                                                    <MdOutlineFeedback/>
                                                </button>
                                            </div>
                                        </span>
                                        <p>{trail.trailDescription}</p>
                                    </div>
                                    <div className="trailPreview">
                                        <h2>Trail Details</h2>
                                        <div className="siteOnTrail" style={{height: '100px'}}>
                                            <span style={{justifyContent: 'center'}}>
                                                <h3>Start of Trail</h3>
                                            </span>
                                            <img src={require('../static/Icons/startMarker.png')}
                                                 style={{height: '90px', minWidth: '90px', width: '90px'}}/>
                                        </div>
                                        {sites && trail.sites.map((trailSite) => {
                                            // Don't remove the conditional statement, this is there as retreiving sites takes time (it's async).
                                            // In other words, we don't show the user the trail preview until we have the sites loaded in by API.
                                            if (sites) {
                                                const site = sites.find((site) => site._id === trailSite);
                                                return (
                                                    <div key={site._id} className="siteOnTrail">
                                                    <span>
                                                        <h3>{(site.siteName.length > 30) && site.siteName.substring(0, 30) + "..."}</h3>
                                                        <h3>{(site.siteName.length <= 30) && site.siteName}</h3>
                                                        <p>{(site.description.length > 140) && site.description.substring(0, 140) + "..."}</p>
                                                        <p>{(site.description.length <= 140) && site.description}</p>
                                                        <a href={"/sites/" + site._id}>
                                                            View Site
                                                            <IoIosArrowForward/>
                                                        </a>
                                                    </span>
                                                        <img src={site.image} alt={site.siteName}/>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className={"trailPreview"}>
                                                        <Spinner/>
                                                        <p>Loading...</p>
                                                    </div>
                                                )
                                            }
                                        })}
                                        <div className="siteOnTrail" style={{height: '100px'}}>
                                        <span style={{justifyContent: 'center'}}>
                                            <h3>End of Trail</h3>
                                        </span>
                                        <img src={require('../static/Icons/endMarker.png')}
                                             style={{height: '90px', minWidth: '90px', width: '90px'}}/>
                                    </div>
                                </div>
                                </div>
                                <div className="trailMap">
                                    <MapContainer
                                        className={"osm"}
                                        center={[57.1497, -2.0943]}
                                        zoom={13.5}
                                        style={{height: '100%', width: '100%', borderRadius: '15px'}}
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {
                                            currentLoc[0] != null &&
                                            <Marker position={currentLoc} icon={getIcon(30, 'userLocMarker')}/>
                                        }
                                        {
                                            trail.sites.map((trailSite, index) => {
                                                if (sites) {
                                                    index = index + 1;
                                                    const site = sites.find((site) => site._id === trailSite);
                                                    waypoints.push([site.latitude, site.longitude]);
                                                    if (index === 1) {
                                                        return (
                                                            <Marker key={sites._id}
                                                                    position={[site.latitude, site.longitude]}
                                                                    icon={getIcon(60, 'startMarker')}/>
                                                        )
                                                    } else if (index === trail.sites.length) {
                                                        return (
                                                            <Marker key={sites._id}
                                                                    position={[site.latitude, site.longitude]}
                                                                    icon={getIcon(60, 'endMarker')}/>
                                                        )
                                                    } else {
                                                        return (
                                                            <Marker key={sites._id}
                                                                    position={[site.latitude, site.longitude]}
                                                                    icon={getIcon(40, 'siteMarker')}/>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                        <Routing id={"trailPath"} waypoints={waypoints}/>
                                    </MapContainer>
                                </div>
                            </span>
                        </div>
                    )}
                </div>
                <AUFooter/>
            </div>
        )
    }
}


export default TrailDetail;