/**
 * APP.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import NaviBar from './components/NaviBar';
import { useAuthContext } from "./hooks/useAuthContext";

// Pages used in this app must be imported below, AND a new <Route> added to the <Routes> element below.
import Home from './pages/Home';
import Maps from "./pages/Maps";
import Sites from "./pages/Sites";
import SiteDetail from "./pages/SiteDetail";
import HelpPage from "./pages/Help";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import CMSLanding from "./pages/protected/CMSLanding";
import TrailDetail from "./pages/TrailDetail";
import sitesEditModal from "./pages/protected/SitesEditModal";
import NewUserSetup from "./pages/protected/NewUserSetup";

/**
 * This is the root of the application. This file contains the React-Router (which controls what URL shows what page).
 * We can also add any components we want to show GLOBALLY (on every page) here.
 */

function App() {
    const { user } = useAuthContext();

    return (
        <Router>
            {/* Add any global components to be shown above page content below this line. */}
            <NaviBar />

            {/* Page contents. */}
            <div className="app">
                <div className="appContent">
                    {/* Insert any new routes below the <Routes> tag below. */}
                    <Routes>
                        {/* Public Routes: these are available to access at all times. */}
                        <Route path="/" element={<Home />} />
                        <Route path="/maps/" element={<Maps />} />
                        <Route path="/sites/" element={<Sites />} />
                        <Route path="/sites/:siteID" element={<SiteDetail />} />
                        <Route path="/trails/:trailID" element={<TrailDetail />} />
                        <Route path="/help/" element={<HelpPage />} />
                        {/* Protected Routes: these will only be available IF the user is logged in. */}
                        <Route path="/login/" element={!user ? <Login /> : (user && user.firstTimeLogin === "true") ? <NewUserSetup /> : <Navigate to={"/"} />} />
                        <Route path="/profile/" element={!user ? <Login /> : (user && user.firstTimeLogin === "true") ? <NewUserSetup /> : <CMSLanding />} />
                        <Route path="/newuser/" element={(user && user.firstTimeLogin === "true") ? <NewUserSetup /> : <Login />} />
                        {/*<Route path="/profile/sites/upload/" element={!user ? <Navigate to={"/login/"} /> : <SiteUpload />} />*/}
                        {/* Any route that is not stated above will redirect to the Error 404 page below. */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
