/**
 * ROUTINGMACHINE.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Import dependencies:
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { useEffect } from "react";

/**
 * This is responsible for generating a route via an array of waypoints, passed in via 'waypoints'.
 * The result is a line drawn on the map, which is the route between the waypoints.
 * Must be used inside a <MapContainer> to work, and a set of waypoints passed in as a prop.
 */

const Routing = ({ waypoints }) => {
    // Get map object from Leaflet.
    const map = useMap();

    // The useEffect hook is used to asynchronously draw the route lines onto the map instance.
    useEffect(() => {
        let routingControl = L.Routing.control({
            waypoints,
            lineOptions: {
                // Adjust the style of the line being drawn.
                styles: [
                    {
                        color: "#DA535D",
                        opacity: 0.8,
                        weight: 4
                    }
                ]
            },
            createMarker: function() {
                // Deletes the waypoint markers generated by the Routing Machine, as these have already been drawn
                // in the Map component.
                return null;
            },
            addWaypoints: false,
            draggableWaypoints: false,
            fitSelectedRoutes: false,
            showAlternatives: false
        }).addTo(map);
    }, [map, waypoints]);

    return null;
};

export default Routing;
