/**
 * CMSFUNCTIONS.JS - ABERDEEN UNCOVERED
 * DESIGNED AND IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

import React, { useState, useEffect } from 'react';

export const handleChangePassword = (e, user, oldPw, newPw, confirmPw) => {
    return new Promise(async (resolve, reject) => {
        if (oldPw === "" || newPw === "" || confirmPw === "") {
            reject("Please fill in all fields.");
        } else if (newPw !== confirmPw) {
            reject("Passwords do not match.");
        } else {
            e.preventDefault();
            try {
                const reqBody = {
                    username: user.username,
                    token: user.token,
                    password: oldPw,
                    newPassword: newPw
                }
                const res = await fetch(process.env.REACT_APP_API_URL + '/api/users/changepassword/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify(reqBody)
                });
                const data = await res.json();
                if (res.status === 200) {
                    resolve("Password has been updated successfully.");
                } else {
                    reject(data.error);
                }
            } catch (error) {
                reject(error);
            }
        }
    });
}

export const handleAddUser = (e, user, username, password, accountType) => {
    return new Promise(async (resolve, reject) => {
        if (username === "" || password === "" || accountType === "") {
            reject("Please fill in all fields.");
        } else {
            e.preventDefault();
            try {
                const reqBody = {
                    username: username,
                    password: password,
                    accountType: accountType,
                    firstTimeLogin: "true",
                }
                const res = await fetch(process.env.REACT_APP_API_URL + '/api/users/signup/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    },
                    body: JSON.stringify(reqBody)
                });
                const data = await res.json();
                if (res.status === 200) {
                    resolve("User has been added successfully.");
                } else {
                    reject(data.error);
                }
            } catch (error) {
                reject(error);
            }
        }
    });
}