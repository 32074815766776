import React, { useState }from "react";
import { FaStar } from "react-icons/fa"

const StarRating = ({ rating, setRating, disabled }) => {
    const [hover, setHover] = useState(null);
    
    return (
        <div className="starloc">
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;

                return (
                    <label key={i}>
                        <input 
                            type="radio" 
                            name="rating" 
                            value={ratingValue} 
                            onClick={() => !disabled && setRating(ratingValue)}
                        />
                        <FaStar 
                            size={20} 
                            color={ratingValue <= (hover || rating) ? "#FFAE42" : "#7d7f7c"}
                            onMouseEnter={() => !disabled && setHover(ratingValue)}
                            onMouseLeave={() => !disabled && setHover(null)}
                        />
                    </label>
                );
            })}
        </div>
    );
};

export default StarRating;