/**
 * SITEDETAIL.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import {React, useState} from 'react';
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import {Alert, Button, Modal, Spinner} from "flowbite-react";
import {HiInformationCircle, HiOutlineExclamationCircle, HiOutlineX} from "react-icons/hi";
import NotFound from "./NotFound";
import AUFooter from "../components/Footer";
import '../styles/SiteDetail.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Link } from 'react-router-dom';
import {useAuthContext} from "../hooks/useAuthContext";
import {ImBin} from "react-icons/im";
import {MdOutlineEdit, MdOutlineFeedback} from "react-icons/md";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StarRating from '../components/StarRating.js';
import TrailUploadModal from "./protected/TrailUploadModal";
import {FaExpand} from "react-icons/fa";
import SitesEditModal from "./protected/SitesEditModal";
import TrailEditModal from "./protected/TrailEditModal";
import {deleteObject, getStorage, ref} from "firebase/storage";

/**
 * This page displays the details of an individual site.
 */

// Get icon for map markers.
function getIcon(_iconSize, iconType) {
    return L.icon({
      iconUrl: require(`../static/Icons/${iconType}.png`),
      iconSize: [_iconSize, _iconSize],
    });
  }

const SiteDetail = () => {
    // Get site ID from the URL, then pass this ID to the useFetch API to obtain specific site data.
    const { siteID } = useParams();
    const { data: site, error, isPending } = useFetch(process.env.REACT_APP_API_URL + "/api/sites/" + siteID);
    const { user } = useAuthContext();

    // Feedback form state.
    const [comment, setComment] = useState("");
    const [rating, setRating] = useState(0);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    // Image Expansion Modal state
    const [showImageModal, setShowImageModal] = useState(false);

    // Modal States
    const [showDeleteSiteModal, setShowDeleteSiteModal] = useState(false);
    const [showEditSiteModal, setShowEditSiteModal] = useState(false);
    const [deleteSiteId, setDeleteSiteId] = useState(null);

    // Submit feedback to the backend.
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Check if user has entered a rating and feedback before submitting.
            if (rating === 0) {
                alert('Please enter a rating before submitting.');
                return;
            }
            // Check if user has entered a comment before submitting.
            if (comment.trim() === '') {
                alert('Please enter feedback before submitting.');
                return;
            }
            // Create new feedback object.
            const newFeed = {
                feedbackType: 'Site',
                rating: rating,
                siteID: siteID,
                trailID: '',
                comment: comment
            };

            const res = await fetch(process.env.REACT_APP_API_URL + "/api/feedback/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newFeed)
            });

            if (res.ok) {
                alert('Feedback recorded!');
                setRating(0);
                setComment('');
            } else {
                alert('Feedback failed to record. Please try again.');
            }
        } catch (error) {
            alert(error);
        }
    };

    const removeSiteFromTrail = (siteId) => {
        // This function removes a given site ID from all trails on the server - used as part of the handleDelete()
        // method.
        const url = process.env.REACT_APP_API_URL + "/api/trails/";
        fetch(url)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Cannot delete site from trail - unable to fetch trails from server.");
                }
                return res.json();
            })
            .then((trails) => {
                trails.forEach((trail) => {
                    const index = trail.sites.indexOf(siteId);
                    if (index > -1) {
                        trail.sites.splice(index, 1);
                        fetch(url + trail._id, {
                            method: 'PATCH',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(trail)
                        })
                            .then((res) => {
                                if (!res.ok) {
                                    throw new Error("Failed to remove site from trail.");
                                }
                            })
                    }
                })
            })
            .catch((error) => {
                console.error("Failed to remove site from trail: " + error);
            })
    }

    const handleDeleteSite = async (site) => {
        const url = process.env.REACT_APP_API_URL + "/api/sites/" + site._id;

        // TODO: Look for where site is inside trails and remove it from there so as to not impact the trail.
        await removeSiteFromTrail(site._id);

        const storage = getStorage();
        const siteRef = ref(storage, site.image);
        deleteObject(siteRef)
            .then(() => {
                const audioRef = ref(storage, site.audio);
                deleteObject(audioRef)
                    .then(() => {
                        fetch(url, {
                            method: 'DELETE',
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }).then((res) => {
                            if (!res.ok) {
                                throw new Error("Failed to delete");
                            }
                            if (res.ok) {
                                alert("Site deleted!");
                                window.location.href = "/sites";
                            }
                        }).catch((e) => {
                            console.log(e);
                        })
                    })
            });
    }

    if (isPending) {
        return (
            <div className={'subpage'}>
                <div className={"loadingContent"}>
                    <Spinner size={'xl'} color={'pink'} />
                    <p>Loading Content...</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            {/* CMS EDIT MODALS */}
            <Modal position={"center"} dismissible size='6xl' show={showEditSiteModal} onClose={() => setShowEditSiteModal(false)}>
                <Modal.Header>
                    Edit Site
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <SitesEditModal site={site.deleteSiteId = site} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showDeleteSiteModal} size="md" onClose={() => setShowDeleteSiteModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle style={{width: '50px', height: '50px'}} className="mx-auto mb-4 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this site?
                        </h3>
                        <p className="mb-5 text-sm font-normal text-gray-500 dark:text-gray-400">
                            The site will no longer be available to users to view. Trails which contain this site will also be affected.
                        </p>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => {
                                handleDeleteSite(deleteSiteId);
                                setShowDeleteSiteModal(false)
                            }}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => {
                                setShowDeleteSiteModal(false);
                                setDeleteSiteId(null);
                            }}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* PUBLIC MODALS*/}
            <Modal position={"center"} dismissible size='2xl' show={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}>
                <Modal.Header>
                    Leave Feedback
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <form onSubmit={handleSubmit} className="formCard">
                            <label className='label2'>How would you rate your experience?</label>
                            <span>
                                <StarRating rating={rating} enabled={true} setRating={setRating} />
                            </span>
                            <label className='label1'>What did you think of this site?</label>
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className='textbox'/>
                            <button type="submit" onClick={handleSubmit} className='submit'>Submit</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal position={"center"} dismissible size='2xl' show={showImageModal} onClose={() => setShowImageModal(false)}>
                <Modal.Header>
                    Full Size Image Set
                </Modal.Header>
                <Modal.Body>
                    {site.image.map((image, index) => (
                        <div key={index}>
                            <img src={image} style={{borderRadius: '10px'}}/>
                            <p style={{marginBottom: '20px', marginTop: '5px'}}><i>Image {index + 1} of {site.image.length}</i></p>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            {
                // Shows alert banner if there's been an error loading data in.
                error &&
                <div>
                    <Alert className={"globalAlerts"} color="failure" icon={HiOutlineX}>
                        <span className="font-medium">Cannot fetch site data!</span> { error }
                    </Alert>
                    <NotFound />
                </div>

            }
            {
                // If we're still waiting on a site to load, show this div, which tells user we're loading content.
                !site &&
                <div className={'subpage'}>
                    <div className={"loadingContent"}>
                        <Spinner size={'xl'} color={'pink'}/>
                        <p>Loading Content...</p>
                    </div>
                </div>
            }
            {
                // If we've loaded a site, display the site's data.
                site && (
                    <div>
                        <div className="hero">
                            <img src={site.image[0]} alt={site.siteName}/>
                            <h1>{site.siteName}</h1>
                        </div>
                        <div className="pgBody">
                            <p className="path"><Link to={"/sites/"}>SITES</Link> / {site.siteName}</p>
                            {
                                // If user is logged in, display edit/delete options.
                                user && (
                                    <div className="editOptions">
                                        <Link to={``}>
                                            <button
                                                className="cta"
                                                onClick={(e) => {
                                                    setShowEditSiteModal(true);
                                                    setDeleteSiteId(site._id);
                                                }}
                                            >
                                                <MdOutlineEdit />
                                                <p>Edit</p>
                                            </button>
                                        </Link>
                                        <Link to={``}>
                                            <button
                                                className="cta"
                                                onClick={(e) => {
                                                    setShowDeleteSiteModal(true);
                                                    setDeleteSiteId(site._id);
                                                }}
                                            >
                                                <ImBin />
                                                <p>Delete</p>
                                            </button>
                                        </Link>
                                    </div>
                                )
                            }

                            {/*  Shows the map with a zoomed in image of just marker for the current site being previewed */}
                            <MapContainer center={[site.latitude, site.longitude]} zoom={15} className="siteMap">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                <Marker position={[site.latitude, site.longitude]} icon={getIcon(40, 'siteMarker')}/>
                                {/* We can still add the marker button so when pressed it does something */}
                            </MapContainer>

                            <span>
                                <div className="siteInfo">
                                    <p>{site.description}</p>
                                    {
                                        site.audio && (
                                            <audio controls={true}>
                                                <source src={site.audio} type="audio/mpeg"/>
                                            </audio>
                                        )
                                    }
                                    <button className="cta" style={{width: '125px'}} onClick={(e) => setShowFeedbackModal(true)}>
                                        <p>Feedback</p>
                                        <MdOutlineFeedback />
                                    </button>
                                </div>
                                <div className="imgGallery">
                                    <Carousel>
                                        {site.image.map((image, index) => (
                                            <div key={index}>
                                                <img src={image}/>
                                            </div>
                                        ))}
                                    </Carousel>
                                    <button className={"cta"} style={{width: '165px'}} onClick={() => setShowImageModal(true)}>
                                        <p>Full Size Images</p>
                                        <FaExpand />
                                    </button>
                            </div>
                        </span>
                    </div>
                </div>
                )
            }
            <AUFooter/>
        </div>
    );
}

export default SiteDetail;