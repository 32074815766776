/**
 * FEEDBACKCONTEXT.JS - ABERDEEN UNCOVERED
 * Designed and implemented by Team Bravo in 2024.
 */

import React, { createContext, useState } from 'react';

export const FeedbackContext = createContext();

export const feedbackReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FEEDBACK':
            return {
                feedback: action.payload
            }
        case 'CREATE_FEEDBACK':
            return {
                feedback: [action.payload, ...state.feedback]
            }
        case 'DELETE_FEEDBACK':
            return {
                feedback: state.feedback.filter((f) => f._id !== action.payload._id)
            }
        default:
            return state
    }
}

export const FeedbackContextProvider = ({ children }) => {
    const [state, dispatch] = useState({
        feedback: null
    })

    return (
        <FeedbackContext.Provider value={{ ...state, dispatch }}>
            { children }
        </FeedbackContext.Provider>
    )
}