/**
 * USELOGIN.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Import dependencies for this hook:
import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

/**
 * This hook handles the login process for the application. Upon initiating a login request, the hook will accept a
 * username and password, then send a request to the API to verify the credentials. If the credentials are valid, the
 * user will be logged in and the auth context will be updated. If the credentials are invalid, the hook will return
 * an error message.
 *
 * Reference: The Net Ninja - MERN Auth Tutorial #12 - https://www.youtube.com/watch?v=ZVHoVxfU5LU
 */

export const useLogin = () => {
    // Initialise state variables.
    const [error, setError] = useState(null)                // Stores any error encountered during login.
    const [isLoading, setIsLoading] = useState(null)        // States if login request is in progress.
    const { dispatch } = useAuthContext()                             // Updates the auth context upon successful login.

    const login = async (username, password) => {
        setIsLoading(true)
        setError(null)

        // Send a POST request to the API to verify the credentials, storing the results inside 'json' variable.
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, password })
        })
        const json = await response.json()

        // If the response received from the API is not OK, we update the error state with the error message.
        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }

        // If the response received is OK, we update the auth context and local storage with the user details.
        if (response.ok) {
            localStorage.setItem('user', JSON.stringify(json))
            dispatch({type: 'LOGIN', payload: json})
            setIsLoading(false)
        }
    }

    // The response object contains the login function, the loading state, and the error state, and is returned to use
    // within the frontend.
    return { login, isLoading, error }
}