/**
 * HELP.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { React, useState } from 'react';
import AUFooter from "../components/Footer";
import { Accordion } from 'flowbite-react';
import "../styles/Help.css";
import {Link, useNavigate} from 'react-router-dom';
import {useAuthContext} from "../hooks/useAuthContext";
import {HiUser} from "react-icons/hi";


/**
 * This page displays some help on using the service to the client..
 */

const HelpPage = () => {
    const navigate = useNavigate();
    const [comment, setComment] = useState('');
    const { user, dispatch } = useAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (comment === '') {
                alert('Please enter feedback before submitting.');
                return;
            }

            const newFeed = {
                feedbackType: 'App',
                rating: '',
                siteID: '',
                trailID: '',
                comment: comment
            }

            const res = await fetch(process.env.REACT_APP_API_URL + "/api/feedback/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newFeed)
            });

            if (res.ok) {
                window.alert('Feedback recorded!');
                navigate(`/`);
            } else {
                window.alert('Feedback failed to record. Please try again.');
            }
        } catch (error) {
            alert(error);
        }
    }
 
    return (
        <div>
            <div className="subpage">
                <h1>Help</h1>
                <p>Need help using our website? Here are a few frequently asked questions.</p>
                <div style={{height: '20px'}} />
                <Accordion>
                    <Accordion.Panel>
                        <Accordion.Title>
                            <p>How do I start a new trail?</p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <p>Trails are accessible from the <Link to={"/maps/"}><u>map</u></Link>.</p>
                            <p>Trails are denoted by larger circles on the map, while individual sites are denoted by smaller circles.</p>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title>
                            <p>How do I access the Content Manager?</p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <p>The Content Manager is a tool for managing the sites and trails hosted on Aberdeen Uncovered.</p>
                            <p>You can <a style={{textDecoration: "underline"}} href={"/login/"}>login to the Content Manager here</a>, or simply click the User icon ({<HiUser style={{display: 'inline-block'}} />}) located at the top right corner of the menu bar.</p>
                            <p>You can only access the Content Manager from a desktop device.</p>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title>
                            <p>Got Feedback?</p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <p>
                                We appreciate any feedback you have about Aberdeen Uncovered. Please provide your
                                feedback below.
                            </p>
                            <form>
                                <label htmlFor="feedback" className='label1'>Feedback:</label>
                                <textarea id="feedback" name="feedback" onChange={(e) => setComment(e.target.value)} className='textbox'/>
                                <button onClick={handleSubmit} className='submit'>Submit</button>
                            </form>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
                {
                    user !== null ? (
                        <div style={{marginTop: '20px'}}>
                            <h2>Content Manager Help</h2>
                            <div style={{height: '20px'}} />
                            <Accordion>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        <p>How do I create a new site or trail?</p>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <p>Sites and Trails can be created by accessing the Content Manager. Click your username at the top-right corner of the Menu Bar to access the Content Manager.</p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        <p>How do I edit or delete sites and trails?</p>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <p>Sites and Trails can be edited or deleted by accessing the Content Manager. Click your username at the top-right corner of the Menu Bar to access the Content Manager.
                                            Sites can also be edited or deleted by accessing their individual site page.
                                        </p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        <p>How do I access user engagement?</p>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <p>User Engagement is within the Content Manager which is accessed by clicking your username at the top-right corner of the Menu Bar.
                                            This will show an overview of average ratings and feedback. More detailed feedback can be seen by clicking 'View all' where you can 
                                            toggle between Site and Trail feedback.
                                        </p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>
                        </div>
                    ) : (
                        <div />
                    )
                }
            </div>
            <AUFooter/>
        </div>

    );
}

export default HelpPage;