import {React, useContext, useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {SitesContext} from "../../context/SitesContext";
import {Spinner} from "flowbite-react";

const AppFeedbackModal = () => {
    const {data, isPending, error} = useFetch(process.env.REACT_APP_API_URL + '/api/feedback/');
    const {sites, dispatch} = useContext(SitesContext);

    return (
        <div className="app-feedback-modal">
            {
                data && (
                    <p style={{marginBottom: '10px'}}>Total App Feedback: {data.filter((itm) => itm.feedbackType === "App").length}</p>
                )
            }
            {
                data ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Feedback Left</th>
                                <th>Feedback Type</th>
                                <th>Rating</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.filter((itm) => itm.feedbackType === "App").map((feedback) => {
                            var date = new Date(feedback.createdAt);
                            return (
                                <tr key={feedback._id}>
                                    <td>{date.toLocaleDateString()} at {date.toLocaleTimeString().substring(0, 5)}</td>
                                    <td>{feedback.feedbackType}</td>
                                    <td>{feedback.rating}</td>
                                    <td>{
                                        feedback.comment ? feedback.comment : (<i>User didn't supply a comment.</i>)
                                    }</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                ) : (
                    <div className={"loadingContent"} style={{height: '200px'}}>
                        <Spinner size={'xl'} color={'pink'}/>
                        <p>Loading Content...</p>
                    </div>
                )
            }
        </div>
    )
}

export default AppFeedbackModal;