/**
 * AUTHCONTEXT.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { createContext, useReducer, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

/**
 * This Context acts as a global 'logged in' variable for the application. This is used to determine the login status
 * at present, therefore determining access permissions to the CMS.
 *
 * Reference: The Net Ninja - MERN Auth Tutorial #8 - https://www.youtube.com/watch?v=64RiVcXhxN0
 */

export const AuthContext = createContext();

// authReducer takes in the current state and an action, and returns the new state after the action has been applied.
export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { user: action.payload }
        case 'LOGOUT':
            localStorage.setItem('user', null);
            localStorage.removeItem('user');
            return { user: null }
        default:
            return state
    }
}

// AuthContextProvider is a wrapper for the application, which provides the AuthContext to all children.
export const AuthContextProvider = ({ children }) => {
    // This is the initial state of the context upon starting the application - i.e no user is logged in.
    const [state, dispatch] = useReducer(authReducer, {
        user: null
    })

    // This useEffect hook is used to check if the user is logged in upon starting the application (by checking the
    // local storage). If so, the user is logged in again using the dispatch() function.
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            const token = jwtDecode(user.token);

            if (user && token.exp * 1000 < Date.now()) {
                dispatch({ type: 'LOGOUT' });
            }

            if (user && token.exp * 1000 > Date.now()) {
                dispatch({ type: 'LOGIN', payload: user });
            }
        }
    }, [])

    // Return auth context provider. The 'children' are the components nested within the auth context (see index.js).
    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>
    )
}