/**
 * USESITESCONTEXT.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Import dependencies for this hook:
import { SitesContext } from '../context/SitesContext';
import { useContext } from 'react';

/**
 * This hook is used to access the SitesContext created in SitesContext.js
 */

export const useSitesContext = () => {
    const context = useContext(SitesContext);

    if (!context) {
        throw Error('useSitesContext must be used inside an SitesContextProvider');
    }

    return context;
}