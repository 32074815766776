/**
 * INDEX.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from "./context/AuthContext";
import {SitesContextProvider} from "./context/SitesContext";
import { TrailContextProvider } from "./context/TrailContext";
import {FeedbackContextProvider} from "./context/FeedbackContext";

/**
 * This is the root of the Aberdeen Uncovered frontend. This won't contain much else other than some React Context
 * components that will wrap the entire application (refs: https://react.dev/reference/react/hooks#context-hooks).
 * The 'root' of the application is contained in APP.JS
 * This file can (for the most part) be left alone!
 */

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <FeedbackContextProvider>
            <SitesContextProvider>
                <TrailContextProvider>
                    <AuthContextProvider>
                        <App />
                    </AuthContextProvider>
                </TrailContextProvider>
            </SitesContextProvider>
        </FeedbackContextProvider>
    </React.StrictMode>
);