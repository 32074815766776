/**
 * FIREBASE.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this file.
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAFhagzI5FskjN4cL27xp6AmwKTKP9sbm4",
    authDomain: "abdnuncovered-2beac.firebaseapp.com",
    projectId: "abdnuncovered-2beac",
    storageBucket: "abdnuncovered-2beac.appspot.com",
    messagingSenderId: "701746906455",
    appId: "1:701746906455:web:e36c6ba27862f56683aa12"
};

// Initialise Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);