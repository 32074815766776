/**
 * SITECARD.JS  - ABERDEEN UNCONVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

import {React, useEffect} from 'react';
import StarRating from "./StarRating";
import {IoIosArrowForward} from "react-icons/io";
import { useState } from 'react';
import "../index.css"
import "../styles/SiteCard.css";
import {FaMapMarkerAlt} from "react-icons/fa";
import {Spinner} from "flowbite-react";
import {useFeedbackContext} from "../hooks/useFeedbackContext";

function calculateDistance(destLat, destLong) {
    // This calculates the distance between the user's location and the location of a given site.
    // Reference: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLon = position.coords.longitude;

                    const R = 6371; // Radius of the Earth in kilometers
                    const dLat = (destLat - userLat) * (Math.PI / 180);
                    const dLon = (destLong - userLon) * (Math.PI / 180);
                    const a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(userLat * (Math.PI / 180)) *
                        Math.cos(destLat * (Math.PI / 180)) *
                        Math.sin(dLon / 2) *
                        Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    const distance = Math.round(((R * c) / 1.609) * 10) / 10; // Distance in miles
                    resolve(distance.toString());
                },
                (error) => {
                    console.error(`Error getting user's location: ${error.message}`);
                    reject(error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
            reject(new Error('Geolocation not supported'));
        }
    });
}

const calculateRating = ( siteID ) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
        const json = await response.json();
        if (response.ok) {
            const siteFeedback = json.filter(f => f.siteID === siteID);
            let rating = 0;
            for (let i = 0; i < siteFeedback.length; i++) {
                rating += Number(siteFeedback[i].rating);
            }
            rating = Math.round(rating / siteFeedback.length);
            resolve(rating);
        } else {
            reject("Error fetching feedback");
        }
    });
}

const SiteCard = ({site}) => {
    const [distances, setDistances] = useState({});       // Store distances between user location & sites
    const [rating, setRating] = useState(0);
    const {feedback, dispatch} = useFeedbackContext();

    useEffect(() => {
        calculateDistance(site.latitude, site.longitude)
            // Note this function requires active access to the user's location. If this fails for any reason,
            // we'll store the value of the distance as "X". We then pick up on this inside the JSX rendering
            // block below. If there's an "X", we don't display that site's distance box at all.
            .then(
                distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: distance }))
            )
            .catch(
                distance => setDistances(prevDistances => ({ ...prevDistances, [site._id]: "X" })),
            );

        const fetchFeedback = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
            const json = await response.json();
            if (response.ok) {
                dispatch({type: 'SET_FEEDBACK', payload: json});
            }
        }

        fetchFeedback();
    }, [site._id, dispatch]);

    useEffect(() => {
        calculateRating(site._id)
            .then(avgRating => setRating(avgRating))
            .catch(error => console.error("Error calculating rating:", error));
    }, [site._id]);

    return (
        <div className={"card"}>
            <a href={"/sites/" + site._id}>
                <img src={site.image} alt={site.siteName}/>
                <div className="siteDist">
                    {/* If this site's distance is "X", don't render the distance box. */}
                    {distances[site._id] !== "X" && (
                        <div className={"distBox"}>
                            <FaMapMarkerAlt className="distPin" />
                            {distances[site._id] ? (
                                <p>{distances[site._id]} mi</p>
                            ) : (
                                <p>
                                    <Spinner size={"xs"} style={{marginLeft: '400px'}} color={'pink'} />
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <h1>{(site.siteName.length > 25) ? site.siteName.substring(0, 25) + "..." : site.siteName}</h1>
                <p>{(site.description.length > 120) ? site.description.substring(0, 120) + "..." : site.description}</p>
                <span>
                    <div className="dt" style={{display: 'flex', flexDirection: 'row', gap: '0'}}>
                        <p><b>Rating:</b></p>
                        <StarRating rating={rating} disabled={true} />
                    </div>
                    <a href={"/sites/" + site._id}><p>Explore</p><IoIosArrowForward/></a>
                </span>
            </a>
        </div>
    )
}

export default SiteCard;