/**
 * FOOTER.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2023.
 */

// Dependencies for this page:
import {BsGithub, BsPencil} from 'react-icons/bs';
import {Link} from "react-router-dom";
import {Button, Modal} from "flowbite-react";
import {useState} from "react";
import {GrDeploy} from "react-icons/gr";

/**
 * This is the footer for the app, and is displayed on every page of the app.
 */

const currentYear = new Date().getFullYear();

const AUFooter = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
        /* Credit: https://flowbite.com/docs/components/footer/*/
        <footer id={"dtFooter"} className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-black sm:text-center dark:text-gray-400">
                    © {currentYear} <a href="https://abdn.ac.uk/" className="hover:underline">University of Aberdeen</a>.
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-black dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="https://github.com/uoa-se-bravo/" target="_blank" className="mr-4 hover:underline md:mr-6 ">
                            <BsGithub /> About
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default AUFooter;