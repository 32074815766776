/**
 * USELOGOUT.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { useAuthContext } from './useAuthContext'

/**
 *  This hook handles the logout process of the application. The process is simple, the user is removed from local
 *  storage, then the logout action is dispatched to the AuthContext.
 */
export const useLogout = () => {
    const { dispatch } = useAuthContext()

    const logout = () => {
        localStorage.removeItem('user')
        dispatch({ type: 'LOGOUT' })
    }

    return { logout }
}