/**
 * NAVIBAR.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2023.
 */

// Dependencies for this page:
import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import {
    IoHelpCircleOutline,
    IoHomeOutline,
    IoMapOutline, IoPersonCircleOutline,
    IoTrailSignOutline
} from 'react-icons/io5';
import { HiUser } from "react-icons/hi";

/**
 * This is the navigation bar for the app.
 */

const NaviBar = () => {
    // Authentication variables. useLogout() is the logout handler, and useAuthContext() handles access to the
    // authentication context.
    const { logout } = useLogout();
    const { user } = useAuthContext();

    return (
        <div className="nvb">
            {/* Logo shown on desktop site - also acts as a link to homepage. */}
            <p className="logo">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <span className="upper">ABERDEEN</span>
                    <span className="lower">uncovered</span>
                </Link>
            </p>
            {/* Navigation links - shown as text on desktop, and icons on mobile. */}
            <ul className="nb">
                <li>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <IoHomeOutline className="nvbIcon" size={'60%'} color={'#432A47'} />
                        <p className="nbText">HOME</p>
                    </Link>
                </li>
                <li>
                    <Link to="/maps/" style={{ textDecoration: 'none' }}>
                        <IoMapOutline className="nvbIcon" size={'60%'} color={'#432A47'} />
                        <p className="nbText">MAP</p>
                    </Link>
                </li>
                <li>
                    <Link to="/sites/" style={{ textDecoration: 'none' }}>
                        <IoTrailSignOutline className="nvbIcon" size={'60%'} color={'#432A47'} />
                        <p className="nbText">SITES</p>
                    </Link>
                </li>
                <li>
                    <Link to="/help/" style={{ textDecoration: 'none' }}>
                        <IoHelpCircleOutline className="nvbIcon" size={'60%'} color={'#432A47'} />
                        <p className="nbText">HELP</p>
                    </Link>
                </li>
            </ul>
            {/* Profile login link. Display user profile & logout IF logged in, or user button if not. */}
            <div className="profile" data-testid="navi-profile">
                {user ?
                    <span className="loggedIn" data-testid="navi-loggedin">
                        <Link to="/profile/" style={{ textDecoration: 'none' }}>
                            <p id="profileLink">{user.username}</p>
                        </Link>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <p className="nbText">
                                <button onClick={logout}>Log Out</button>
                            </p>
                        </Link>
                    </span>
                    :
                    <span className="loggedOut" data-testid="navi-loggedout">
                        <Link to="/login/" style={{ textDecoration: 'none' }}>
                            <HiUser size={'30px'} color={'#432A47'} />
                        </Link>
                    </span>
                }
            </div>
        </div>
    );
}

export default NaviBar;