import {React, useContext, useEffect, useState} from 'react';
import useFetch from "../../hooks/useFetch";
import {SitesContext} from "../../context/SitesContext";
import {Modal, Spinner, Tabs} from "flowbite-react";
import {useSitesContext} from "../../hooks/useSitesContext";
import {useTrailContext} from "../../hooks/useTrailContext";
import {MdLocationPin, MdOutlineFeedback} from "react-icons/md";
import {GrOverview} from "react-icons/gr";
import {IoTrailSignOutline} from "react-icons/io5";

const ContentFeedbackModal = () => {
    const {data, isPending, error} = useFetch(process.env.REACT_APP_API_URL + '/api/feedback/');
    const {sites, dispatch: siteDispatch} = useSitesContext();
    const {trails, dispatch: trailDispatch} = useTrailContext();

    const [showCommentModal, setShowCommentModal] = useState(false);
    const [siteId, setSiteId] = useState(null);

    const [showTrailCommentModal, setShowTrailCommentModal] = useState(false);
    const [trailId, setTrailId] = useState(null);

    return (
        <div className="content-feedback-modal">
            <Modal dismissible show={showCommentModal} onClose={() => setShowCommentModal(false)}>
                <Modal.Header>Site Comments</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <table className="site-feedback-table">
                            <thead>
                                <tr>
                                    <th>Date Feedback Left</th>
                                    <th>Rating</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && (data.filter((feedback) => feedback.siteID === siteId).length > 0) ? (
                                        data.filter((feedback) => feedback.siteID === siteId).map((feedback) => (
                                            <tr key={feedback._id}>
                                                <td>{feedback.createdAt.substring(0, 10)} at {feedback.createdAt.substring(11, 16)}</td>
                                                <td>{feedback.rating} / 5</td>
                                                <td>{feedback.comment}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>Users have not left comments for this site.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dismissible show={showTrailCommentModal} onClose={() => setShowTrailCommentModal(false)}>
                <Modal.Header>Trail Comments</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <table className="site-feedback-table">
                            <thead>
                            <tr>
                                <th>Date Feedback Left</th>
                                <th>Rating</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data && (data.filter((feedback) => feedback.trailID === trailId).length > 0) ? (
                                    data.filter((feedback) => feedback.trailID === trailId).map((feedback) => (
                                        <tr key={feedback._id}>
                                            <td>{feedback.createdAt.substring(0, 10)} at {feedback.createdAt.substring(11, 16)}</td>
                                            <td>{feedback.rating} / 5</td>
                                            <td>{feedback.comment}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={3}>Users have not left comments for this trail.</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            {
                data ? (
                    <Tabs style="underline">
                        <Tabs.Item active title="Site Feedback" icon={MdLocationPin}>
                            <table className="site-feedback-table">
                                <thead>
                                    <tr>
                                        <th>Site</th>
                                        <th>Average Rating</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sites && sites
                                            .sort((a, b) => {if (a.siteName < b.siteName) return -1})
                                            .map((site) => (
                                                <tr key={site._id}>
                                                    <td>{site.siteName}</td>
                                                    <td>
                                                        {
                                                            (data.filter((feedback) => feedback.siteID === site._id).reduce((acc, feedback) => Number(acc) + Number(feedback.rating), 0) / data.filter((feedback) => feedback.siteID === site._id).length) || 0
                                                        } / 5
                                                    </td>
                                                    <td>
                                                        <button
                                                            className={"cta"}
                                                            style={{width: '80px'}}
                                                            onClick={(e) => {
                                                                setSiteId(site._id);
                                                                setShowCommentModal(true);
                                                            }}
                                                        >
                                                            <MdOutlineFeedback />
                                                            <p>
                                                                {data.filter((feedback) => feedback.siteID === site._id).filter((feedback) => feedback.comment !== "").length}
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </Tabs.Item>
                        <Tabs.Item title="Trail Feedback" icon={IoTrailSignOutline}>
                            <table className="site-feedback-table">
                                <thead>
                                    <tr>
                                        <th>Trail</th>
                                        <th>Average Rating</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    trails && trails
                                        .sort((a, b) => {
                                            if (a.trailName < b.trailName) return -1
                                        })
                                        .map((trail) => (
                                            <tr key={trail._id}>
                                                <td>{trail.trailName}</td>
                                                <td>
                                                    {
                                                        (data.filter((feedback) => feedback.trailID === trail._id).reduce((acc, feedback) => Number(acc) + Number(feedback.rating), 0) / data.filter((feedback) => feedback.trailID === trail._id).length) || 0
                                                    } / 5
                                                </td>
                                                <td>
                                                    <button
                                                        className={"cta"}
                                                        style={{width: '80px'}}
                                                        onClick={(e) => {
                                                            setTrailId(trail._id);
                                                            setShowTrailCommentModal(true);
                                                        }}
                                                    >
                                                        <MdOutlineFeedback/>
                                                        <p>
                                                            {data.filter((feedback) => feedback.trailID === trail._id).filter((feedback) => feedback.comment !== "").length}
                                                        </p>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>
                        </Tabs.Item>
                    </Tabs>
                ) : (
                    <div className={"loadingContent"} style={{height: '200px'}}>
                        <Spinner size={'xl'} color={'pink'}/>
                        <p>Loading Content...</p>
                    </div>
                )
            }
        </div>
    )
}

export default ContentFeedbackModal;