/**
 * LOGIN.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Dependencies for this page:
import { React, useState } from 'react';
import { useLogin } from '../hooks/useLogin';
import { useMediaQuery } from 'react-responsive';
import { SiAlltrails } from "react-icons/si";
import { HiOutlineCamera, HiOutlineX } from "react-icons/hi";
import { Alert } from "flowbite-react";
import '../styles/LoginPage.css';

/**
 * This page allows a user to log in to the content manager.
 */

const Login = () => {
    // isMobile determines whether we display login form or prompt user to go home (as CMS is not mobile-friendly)
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    // Login form states (email and password)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {login, error, isLoading} = useLogin();

    // Handle login form submission.
    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(username, password);
    }

    if (!isMobile) {
        // Not mobile, so display the login form.
        return (
            <div className="loginPage">
                {/* Background Image */}
                <img
                    className="bgImg"
                    src="https://firebasestorage.googleapis.com/v0/b/abdnuncovered-2beac.appspot.com/o/hero%2Fhero_login.jpeg?alt=media&token=93a2d0be-55d5-47ee-bfb3-909a06fe19da"
                    alt="Aberdeen Beach, Aberdeen"
                />
                {/* Image Credit - do not remove, required as part of Unsplash license. */}
                <div className="hImgCaption">
                    <span>
                        <HiOutlineCamera className="hCaptionIcon" style={{width: "25px", height: "25px", marginBottom: "15px"}} />
                        <p>
                            <a href="https://unsplash.com/@morgskinner?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
                                Morgan Skinner
                            </a> on <a href="https://unsplash.com/s/photos/aberdeen">
                                Unsplash
                            </a>
                        </p>
                    </span>
                </div>
                {/* Login Form */}
                <div className="loginForm" data-testid="loginForm">
                    <h1>Content Manager</h1>
                    <form className={"login"} onSubmit={handleSubmit}>
                        {error && (
                            <Alert className={"loginAlert"} color="failure" icon={HiOutlineX}>
                                <span className="font-medium">{error}</span>
                            </Alert>
                        )}
                        <input
                            type={"text"}
                            placeholder={"Username"}
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            type={"password"}
                            placeholder={"Password"}
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button disabled={isLoading}>Login</button>
                    </form>
                </div>
            </div>
        );
    } else {
        // User is on a mobile device, so advise access to CM system is unavailable.
        return (
            <div className="subpage" data-testid="mobile-login">
                <h1>Use Desktop Website</h1>
                <p>Sorry, the Content Manager is not available to access from a mobile device right now.</p>
                <p>&nbsp;</p>
                <p>Select one of the options below to go back.</p>
                <p>&nbsp;</p>
                <SiAlltrails style={{ marginLeft: "auto", marginRight: "auto" }} size={"60%"} color={"rgba(67,42,71,0.15)"} />
            </div>
        );
    }
}

export default Login;