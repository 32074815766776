/**
 * USEAUTHCONTEXT.JS - ABERDEEN UNCOVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

// Import dependencies for this hook:
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';

/**
 * This hook is used to access the AuthContext created in AuthContext.js.
 * Recall that a context is a state that can be accessed by any child component.
 */

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('useAuthContext must be used within an AuthContextProvider');
    }

    return context;
}