import {React, useEffect} from 'react';
import { useState } from 'react';
import { storage } from '../../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import {useAuthContext} from "../../hooks/useAuthContext";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import {useSitesContext} from "../../hooks/useSitesContext";
import {MdOutlineDelete} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import { useTrailContext } from '../../hooks/useTrailContext';

const TrailEditModal = ({trail}) => {

    // State variables for the form. We use these in the handleSubmit function.
    const { trailName, trailDescription, trailImage, sites: trailSiteIds, _id } = trail.deleteTrailId;
    const [trailNameV, setTrailNameV] = useState(trailName);
    const [trailDescriptionV, setTrailDescriptionV] = useState(trailDescription);
    const [trailImg, setTrailImg] = useState(trailImage);
    const [sitesOnTrail, setSitesOnTrail] = useState([]);
    const trailId = _id;

    // AuthContext is used to pass a valid user token to the backend.
    const { user } = useAuthContext();
    const { sites } = useSitesContext();
    const { dispatch } = useTrailContext();

    useEffect(() => {
        if (sites && trailSiteIds) {
            const sitesOnTrail = trailSiteIds.map(trailSiteId => {
                return sites.find(site => site._id === trailSiteId);
            });
            setSitesOnTrail(sitesOnTrail);
        }
    }, [sites, trailSiteIds]);

    const navigate = useNavigate();

    // Handles form submission. We upload the image to the Firebase storage first, then send a POST request to the
    // backend to create a new Site in the database.
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if user has supplied an image to upload. If not, do not continue.
        if (!trailImg) {
            alert("Please select an image to upload!");
        }

        // The remainder of this function goes in a try/catch block to handle any errors.
        try {

            const updatedTrail = {
                trailName: trailNameV,
                createdBy: user.username,
                trailDescription: trailDescriptionV,
                startingLat: sitesOnTrail[0].latitude,
                startingLong: sitesOnTrail[0].longitude,
                trailImage: trailImg,
                sites: sitesOnTrail.map(site => site._id)
            };

            // Send POST request to backend. We pass the user token in the Authorization header, and the new site
            // object in the body of the request.
            const res = await fetch(process.env.REACT_APP_API_URL + "/api/trails/" + _id, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
                body: JSON.stringify(updatedTrail)
            });

            // If the response is OK, we dispatch the new site to the global state and alert the user that the site
            // was uploaded successfully. Otherwise, we alert the user that there was an error.
            if (res.ok) {
                const responseData = await res.json();
                dispatch({ type: "DELETE_TRAIL", payload: responseData });
                dispatch({ type: "CREATE_TRAIL", payload: responseData });
                alert("Trail updated successfully!");
            } else {
                const errorData = await res.json();
                alert(`Error uploading trail: ${errorData.message}`);
            }
        } catch (error) {
            console.error("Error during image upload or POST request:", error);
            alert("Error during trail update. Please try again.");
        }
    }

    // Displays the update form to the user.
    return (
        <form onSubmit={handleSubmit} className={"upload-form"}>
            <span>
                <div className="side1">
                    <input
                        type="text"
                        id="trailName"
                        name="trailName"
                        value={trailNameV}
                        onChange={(e) => setTrailNameV(e.target.value)}
                        required
                    />
                    <textarea
                        id="trailDescription"
                        name="trailDescription"
                        value={trailDescriptionV}
                        onChange={(e) => setTrailDescriptionV(e.target.value)}
                        required
                    />
                    <div className="select-sites">
                        <p>Add Sites to Trail</p>
                        <div className="site-list">
                            {
                                sites && sites.map((site) => {
                                    return (
                                        <div
                                            onClick={(e) => {
                                                if (sitesOnTrail.includes(site)) {
                                                    alert("You cannot visit the same site twice!");
                                                } else {
                                                    setSitesOnTrail(prevSites => [...prevSites, site]);
                                                }
                                            }}
                                            className="site-card"
                                            key={site._id}
                                        >
                                            <img src={site.image} />
                                            <p>{(site.siteName.length > 30) ? site.siteName.substring(0, 30) + "..." : site.siteName}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        sitesOnTrail && (
                            <div className="sites-on-trail">
                                <p>Trail Preview</p>
                                <div className="itinerary">
                                    <div className="site" style={{justifyContent: 'center'}}>
                                        <img src={require("../../static/Icons/startMarker.png")} />
                                        <p>Start of Trail</p>
                                    </div>
                                    {
                                        sitesOnTrail.map((site) => (
                                            <div className="site" key={site._id}>
                                                <img src={site.image}/>
                                                <p>{(site.siteName.length > 30) ? site.siteName.substring(0, 30) + "..." : site.siteName}</p>
                                                <button onClick={(e) => {
                                                    setSitesOnTrail(prevSites => prevSites.filter(s => s !== site));
                                                }}>
                                                    <MdOutlineDelete style={{width: '20px', height: '25px'}}/>
                                                </button>
                                            </div>
                                        ))
                                    }
                                    <div className="site" style={{justifyContent: 'center'}}>
                                        <img src={require("../../static/Icons/endMarker.png")}/>
                                        <p>End of Trail</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <input className="submitBtn" type="submit"/>
            </span>
        </form>
    )
}

export default TrailEditModal;