/**
 * TRAILCARD.JS  - ABERDEEN UNCONVERED
 * DESIGNED & IMPLEMENTED BY TEAM BRAVO IN 2024.
 */

import {React, useEffect} from 'react';
import StarRating from "./StarRating";
import {IoIosArrowForward} from "react-icons/io";
import { useState } from 'react';
import "../index.css"
import "../styles/SiteCard.css";
import {FaMapMarkerAlt} from "react-icons/fa";
import {Spinner} from "flowbite-react";
import {useFeedbackContext} from "../hooks/useFeedbackContext";
import {GiTrail} from "react-icons/gi";
import {useMediaQuery} from "react-responsive";

function calculateDistance(destLat, destLong) {
    // This calculates the distance between the user's location and the location of a given site.
    // Reference: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLon = position.coords.longitude;

                    const R = 6371; // Radius of the Earth in kilometers
                    const dLat = (destLat - userLat) * (Math.PI / 180);
                    const dLon = (destLong - userLon) * (Math.PI / 180);
                    const a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(userLat * (Math.PI / 180)) *
                        Math.cos(destLat * (Math.PI / 180)) *
                        Math.sin(dLon / 2) *
                        Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    const distance = Math.round(((R * c) / 1.609) * 10) / 10; // Distance in miles
                    resolve(distance.toString());
                },
                (error) => {
                    console.error(`Error getting user's location: ${error.message}`);
                    reject(error);
                }
            );
        } else {
            console.error('Geolocation is not supported by your browser');
            reject(new Error('Geolocation not supported'));
        }
    });
}

const calculateRating = ( trailID ) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
        const json = await response.json();
        if (response.ok) {
            const trailFeedback = json.filter(f => f.trailID === trailID);
            let rating = 0;
            for (let i = 0; i < trailFeedback.length; i++) {
                rating += Number(trailFeedback[i].rating);
            }
            rating = Math.round(rating / trailFeedback.length);
            resolve(rating);
        } else {
            reject("Error fetching feedback");
        }
    });
}

const TrailCard = ({trail}) => {
    const [distances, setDistances] = useState({});       // Store distances between user location & sites
    const [rating, setRating] = useState(0);
    const {feedback, dispatch} = useFeedbackContext();

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    useEffect(() => {
        calculateDistance(trail.startingLat, trail.startingLong)
            // Note this function requires active access to the user's location. If this fails for any reason,
            // we'll store the value of the distance as "X". We then pick up on this inside the JSX rendering
            // block below. If there's an "X", we don't display that site's distance box at all.
            .then(
                distance => setDistances(prevDistances => ({ ...prevDistances, [trail._id]: distance }))
            )
            .catch(
                distance => setDistances(prevDistances => ({ ...prevDistances, [trail._id]: "X" })),
            );

        const fetchFeedback = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/feedback/');
            const json = await response.json();
            if (response.ok) {
                dispatch({type: 'SET_FEEDBACK', payload: json});
            }
        }

        fetchFeedback();
    }, [trail._id, dispatch]);

    useEffect(() => {
        calculateRating(trail._id)
            .then(avgRating => setRating(avgRating))
            .catch(error => console.error("Error calculating rating:", error));
    }, [trail._id]);

    return (
        <div className={"card"}>
            <a href={"/trails/" + trail._id}>
                <img src={trail.trailImage} alt={trail.trailName}/>
                <div className="siteDist">
                    {/* If this site's distance is "X", don't render the distance box. */}
                    {distances[trail._id] !== "X" && (
                        <div className={"distBox"}>
                            <FaMapMarkerAlt className="distPin"/>
                            {distances[trail._id] ? (
                                <p>{distances[trail._id]} mi</p>
                            ) : (
                                <p>
                                    <Spinner size={"xs"} color={'pink'}/>
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <div className={"stopsBox"}>
                    <GiTrail/>
                    <p>{trail.sites.length}</p>
                </div>
                {
                    isMobile ? (
                        <h1>{(trail.trailName.length > 25) ? trail.trailName.substring(0, 25) + "..." : trail.trailName}</h1>
                    ) : (
                        <h1 style={{marginTop: '-25px'}}>{(trail.trailName.length > 25) ? trail.trailName.substring(0, 25) + "..." : trail.trailName}</h1>
                    )
                }
                <p>{(trail.trailDescription.length > 120) ? trail.trailDescription.substring(0, 120) + "..." : trail.trailDescription}</p>
                <span style={{alignSelf: "flex-end"}}>
                    <div className="dt" style={{display: 'flex', flexDirection: 'row', gap: '0'}}>
                        <p><b>Rating:</b></p>
                        <StarRating rating={rating} disabled={true}/>
                    </div>
                    <a href={"/trails/" + trail._id}><p>Explore</p><IoIosArrowForward/></a>
                </span>
            </a>
        </div>
    )
}

export default TrailCard;