import {HiOutlineCamera, HiOutlineX} from "react-icons/hi";
import {Alert} from "flowbite-react";
import {React, useState} from "react";
import {handleChangePassword} from "./CMSFunctions";
import {useAuthContext} from "../../hooks/useAuthContext";

const NewUserSetup = () => {
    // Login form states (email and password)
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const { user, dispatch } = useAuthContext();

    // Handle login form submission.
    const handleSubmit = async (e) => {
        e.preventDefault();

        const res = await handleChangePassword(e, user, oldPassword, newPassword, confirmPassword);
        alert(res);

        const updatedUser = { ...user, firstTimeLogin: "false" };
        localStorage.setItem('user', JSON.stringify(updatedUser));
        dispatch({ type: 'LOGIN', payload: updatedUser });

        window.location.href = "/";
    }

    return (
        <div className={"loginPage"}>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/abdnuncovered-2beac.appspot.com/o/hero%2Fhero_newuser.jpeg?alt=media&token=4047bcef-cee2-4514-8387-9ba30ec1baa8"
                className="bgImg"
                alt="King's College, University of Aberdeen"
                style={{objectPosition: "0 -500px", filter: 'brightness(0.85)'}}
            />
            {/* Image Credit - do not remove, required as part of Unsplash license. */}
            <div className="hImgCaption">
                <span>
                    <HiOutlineCamera className="hCaptionIcon" style={{width: "25px", height: "25px", marginBottom: "15px"}} />
                    <p>
                        Calvin Earnshaw
                    </p>
                </span>
            </div>

            {/* Login Form */}
            <div className="loginForm" style={{width: "600px", height: "400px", top: "calc(50vh - 200px)", left: "calc(50vw - 300px)", backgroundColor: "rgba(255,255,255,0.7)"}}>
                <h1>New User Setup</h1>
                <form className={"login"} onSubmit={handleSubmit}>
                    {error && (
                        <Alert className={"loginAlert"} color="failure" icon={HiOutlineX}>
                            <span className="font-medium">{error}</span>
                        </Alert>
                    )}
                    <input
                        type={"password"}
                        placeholder={"Enter Old Password"}
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <input
                        type={"password"}
                        placeholder={"Enter New Password"}
                        required
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type={"password"}
                        placeholder={"Confirm New Password"}
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default NewUserSetup;